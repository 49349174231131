import { Box, Typography } from "@mui/material";
import { FastField, Formik } from "formik";
import PasswordField from "custom-fields/PasswordField";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import useModalContext from "hooks/useModalContext/useModalContext";
import "./styles.modules.scss";
import { userApi } from "api/tokenApi";
import { PasswordSchema } from "utils/validation";

const ResetPasswordModal = (params: any) => {
  const { id } = params;
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const { setOpen } = useModalContext();

  // const resetPassword = useMutation(userApi.resetPassword, {
  //   onSuccess: (res: any) => {
  //     toast.success(res.message);
  //     setOpen(false);
  //   },
  //   onError: (error: any) => {
  //     toast.error(error.message);
  //     setOpen(false);
  //   },
  // });

  const handleSubmit = async (values: any) => {
    const { password } = values;
    // resetPassword.mutate({ id, password });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => {
        const { password } = values;

        return (
          <Box
            sx={{ my: "16px", mx: "18px" }}
            component={"form"}
            onSubmit={handleSubmit}
          >
            <FastField
              component={PasswordField}
              size="small"
              name="password"
              fullWidth={true}
              placeholder="Enter new password"
              label="Enter new password"
              isDefault={false}
            />
            <ul>
              <li>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: password?.length > 12 ? "green" : "red",
                    mt: "3px",
                  }}
                  variant="h6"
                  className="length_check"
                >
                  At least 12 characters
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: password.match(/^(?=.*\d)/) ? "green" : "red",
                    mt: "3px",
                  }}
                  variant="h6"
                  className="digit_check"
                >
                  Contains at least ONE number character (0 - 9)
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: password.match(/^(?=.*[A-Z])/) ? "green" : "red",
                    mt: "3px",
                  }}
                  variant="h6"
                  className="upper_check"
                >
                  Contains at least ONE uppercase letter
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: password.match(/^(?=.*[a-z])/) ? "green" : "red",
                    mt: "3px",
                  }}
                  variant="h6"
                  className="lower_check"
                >
                  Contains at least ONE lowercase letter
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: password.match(/^(?=.*[@$!%*#?&])/)
                      ? "green"
                      : "red",
                    mt: "3px",
                  }}
                  variant="h6"
                  className="special_check"
                >
                  Contains at least ONE special character
                </Typography>
              </li>
            </ul>

            <FastField
              component={PasswordField}
              size="small"
              name="confirmPassword"
              fullWidth={true}
              className="confirm_password"
              placeholder="Re-enter new password"
              label="Re-enter new password"
              isDefault={false}
            />

            <LoadingButton
              sx={{
                height: 37,
                mt: 1,
                width: "100%",
                textTransform: "none",
                color: "white",
                fontSize: "15px",
              }}
              type="submit"
              variant="contained"
              size="small"
            >
              Submit
            </LoadingButton>
          </Box>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordModal;
