import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import type { ElementType } from "react";
import { useTranslation } from "react-i18next";

interface State {
  showPassword: boolean;
}

interface FieldProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface FormProps {
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

interface Props {
  field: FieldProps;
  form: FormProps;
  label?: string;
  disabled?: boolean;
  Icon?: ElementType;
  placeholder?: string;
  type?: string;
  size?: "small" | "medium" | undefined;
  fullWidth?: boolean;
}

function PasswordField({
  field,
  form,
  label = "",
  disabled = false,
  Icon,
  placeholder = "",
  size = "small",
  fullWidth = true,
}: Props): JSX.Element {
  const { name } = field;
  const { errors, touched } = form;
  const showError = Boolean(errors[name] && touched[name]);
  const { t } = useTranslation();

  const [values, setValues] = useState<State>({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues((prev) => ({
      showPassword: !prev.showPassword,
    }));
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      id={name}
      label={t(label, { defaultValue: label })}
      disabled={disabled}
      type={values.showPassword ? "text" : "password"}
      placeholder={t(placeholder, { defaultValue: placeholder })}
      error={showError}
      helperText={
        showError &&
        t(errors[name] as string, { defaultValue: errors[name] as string })
      }
      size={size}
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {field.value ? (
              <IconButton
                color={showError ? "error" : "primary"}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                sx={{ mr: -1.5 }}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : (
              <IconButton
                color={showError ? "error" : undefined}
                aria-label="toggle password visibility"
                sx={{ mr: -1.5 }}
                disabled
              >
                {Icon && <Icon />}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordField;
