import React, { useMemo, useState } from "react";
import {
  Tooltip,
  IconButton,
  Stack,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Typography,
  Alert,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { useFormikContext } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import classApi from "api/classApi";
import { tokens } from "theme";
import { useNavigate } from "react-router-dom";
import { CREATE } from "constants/ConstantCommon";
import { toast } from "react-toastify";

// Improved time conversion with more robust handling
const convertTime = (time?: string): "morning" | "afternoon" | "noData" => {
  if (!time) return "noData";

  try {
    const hour = parseInt(time.slice(0, 2), 10);
    if (isNaN(hour)) return "noData";

    if (hour >= 0 && hour < 12) return "morning";
    if (hour >= 12 && hour < 24) return "afternoon";

    return "noData";
  } catch (error) {
    console.error("Error converting time:", error);
    return "noData";
  }
};

export const ClassScheduleTable: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State to track which class is being deleted
  const [deletingClassId, setDeletingClassId] = useState<number | null>(null);

  const { values } = useFormikContext();
  const { location, status } = values as any;
  const locationId =
    location?.id && location?.id === "all" ? undefined : location?.id;

  const {
    data: classes,
    isLoading,
    isFetching,
    isError,
    error,
    refetch: refetchClassList,
  } = useQuery({
    queryKey: ["classes", location?.id, status?.code],
    queryFn: () =>
      classApi.getClasses({
        location_id: locationId,
        status: status?.code,
      }),
    retry: 1,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    placeholderData: (previousData) => previousData,
  });

  const { mutate: deleteClass } = useMutation({
    mutationFn: (classId: string | number) => classApi.deleteClass(classId),
    onSuccess: (_, classId) => {
      console.log(`Class ${classId} deleted successfully`);
      toast.success(t("Class deleted successfully"));
      refetchClassList();
      // Reset the deleting class id
      setDeletingClassId(null);
    },
    onError: (error) => {
      console.error("Delete class mutation error:", error);
      toast.error(t("Failed to delete class"));
      // Reset the deleting class id on error
      setDeletingClassId(null);
    },
  });

  const { morning, afternoon } = useMemo(() => {
    const morning: any[] = [[], [], [], [], [], [], []];
    const afternoon: any[] = [[], [], [], [], [], [], []];

    classes?.content?.forEach((item: any) => {
      const timeSlot = convertTime(item?.start_at);
      if (timeSlot !== "noData") {
        const targetArray = timeSlot === "morning" ? morning : afternoon;
        targetArray?.[item?.day_of_week]?.push(item);
      }
    });

    return { morning, afternoon };
  }, [classes]);

  const handleClassSelect = (classroom: any) =>
    classroom?.id && navigate(`${classroom.id}`);

  const handleClassDelete = (id: number) => {
    console.log("Delete class with ID:", id);
    // Set the current class being deleted
    setDeletingClassId(id);
    deleteClass(id);
  };

  const handleAddClick = (dayIndex: number) => {
    navigate(CREATE, {
      state: {
        status,
        location,
        dow: dayIndex,
      },
    });
  };

  const renderLoadingSkeleton = () => (
    <Stack spacing={1} alignItems="center" sx={{ width: "100%", padding: 2 }}>
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          variant="rounded"
          width="100%"
          height={40}
          sx={{ marginBottom: 1 }}
        />
      ))}
    </Stack>
  );

  const renderClassColumn = (
    dayClasses: any[],
    dayIndex: number,
    timePeriod: string
  ) => (
    <TableCell
      key={`${timePeriod}-${dayIndex}`}
      sx={{
        border: `1px solid ${colors.black[100]}`,
        minWidth: 150,
        maxWidth: 250,
        position: "relative",
      }}
    >
      {isLoading ? (
        renderLoadingSkeleton()
      ) : isError ? (
        <Alert
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => refetchClassList()}
            >
              {t("retry")}
            </IconButton>
          }
        >
          <Typography variant="body2">
            {error instanceof Error
              ? error.message
              : t("classSchedule.errorLoadingClasses")}
          </Typography>
        </Alert>
      ) : (
        <>
          {isFetching && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
              }}
            />
          )}
          <Stack
            spacing={1}
            direction="column"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              opacity: isFetching ? 0.7 : 1,
              pointerEvents: isFetching ? "none" : "auto",
            }}
          >
            {dayClasses.length === 0 ? (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textAlign: "center", width: "100%" }}
              >
                {t("classSchedule.noClassesScheduled")}
              </Typography>
            ) : (
              dayClasses.map((classroom, index) => (
                <Chip
                  key={classroom?.id || `${timePeriod}-${dayIndex}-${index}`}
                  variant="outlined"
                  label={`${classroom?.name} ${classroom?.start_at?.slice(
                    0,
                    5
                  )} - ${classroom?.end_at?.slice(0, 5)}`}
                  onClick={() => handleClassSelect(classroom)}
                  deleteIcon={
                    deletingClassId === classroom?.id ? (
                      <CircularProgress size={20} />
                    ) : undefined
                  }
                  onDelete={() => handleClassDelete(classroom?.id)}
                  disabled={deletingClassId === classroom?.id}
                  sx={{
                    fontWeight: "bold",
                    width: "100%",
                    justifyContent: "space-between",
                    "& .MuiChip-deleteIcon": {
                      margin: 0,
                    },
                  }}
                />
              ))
            )}

            {location && (
              <Tooltip title={t("classSchedule.addClassTooltip")} arrow>
                <IconButton
                  aria-label="add"
                  onClick={() => handleAddClick(dayIndex)}
                  sx={{ marginTop: 1 }}
                  disabled={isFetching}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </>
      )}
    </TableCell>
  );

  // Days of the week array with translations
  const daysOfWeek = [
    t("classSchedule.days.sunday"),
    t("classSchedule.days.monday"),
    t("classSchedule.days.tuesday"),
    t("classSchedule.days.wednesday"),
    t("classSchedule.days.thursday"),
    t("classSchedule.days.friday"),
    t("classSchedule.days.saturday"),
  ];

  return (
    <TableContainer
      component={Paper}
      elevation={8}
      sx={{
        borderRadius: 0,
        width: "100%",
        overflowX: "auto",
        position: "relative",
      }}
    >
      <Table sx={{ minWidth: 800 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                border: `1px solid ${colors.black[100]}`,
                fontWeight: "bold",
              }}
            >
              {t("classSchedule.time")}
            </TableCell>

            {daysOfWeek.map((day, index) => (
              <TableCell
                key={`day-${index}`}
                align="center"
                sx={{
                  border: `1px solid ${colors.black[100]}`,
                  fontWeight: "bold",
                }}
              >
                {day}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Morning Row */}
          <TableRow sx={{ height: 250 }}>
            <TableCell
              sx={{
                border: `1px solid ${colors.black[100]}`,
                fontWeight: "bold",
              }}
            >
              {t("classSchedule.morning")}
            </TableCell>
            {morning.map((dayClasses, index) =>
              renderClassColumn(dayClasses, index, "morning")
            )}
          </TableRow>

          {/* Afternoon Row */}
          <TableRow sx={{ height: 250 }}>
            <TableCell
              sx={{
                border: `1px solid ${colors.black[100]}`,
                fontWeight: "bold",
              }}
            >
              {t("classSchedule.afternoon")}
            </TableCell>
            {afternoon.map((dayClasses, index) =>
              renderClassColumn(dayClasses, index, "afternoon")
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
