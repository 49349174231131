import React from "react";
import { Typography, useTheme } from "@mui/material";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SvgIconComponent } from "@mui/icons-material";

// Define a more strict type for menu items
interface SubMenuItem {
  title: string;
  path: string;
  icon?: SvgIconComponent;
}

interface SidebarMenuItemProps {
  title: string;
  to?: string;
  Icon?: SvgIconComponent;
  submenu?: SubMenuItem[];
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  title,
  to,
  Icon,
  submenu,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  // Render submenu items
  if (submenu) {
    return (
      <SubMenu
        onClick={to ? () => navigate(to) : undefined}
        label={t(title, { defaultValue: title })}
        icon={Icon ? <Icon /> : null}
      >
        {submenu.map((subItem, index) => {
          const SubIcon = subItem.icon;
          return (
            <NavLink
              key={index}
              style={{ textDecoration: "none" }}
              to={subItem.path}
            >
              {({ isActive }) => (
                <MenuItem
                  component="div" // Changed to div to preserve pro-sidebar animations
                  active={isActive} // Explicitly set active prop
                  style={{
                    color: isActive
                      ? theme.palette.primary.light
                      : theme.palette.text.primary,
                    backgroundColor: isActive
                      ? theme.palette.grey[300]
                      : theme.palette.background.paper,
                    position: "relative",
                  }}
                  icon={SubIcon ? <SubIcon /> : null}
                  className="menu-item-hover"
                >
                  <Typography>
                    {t(subItem.title, { defaultValue: subItem.title })}
                  </Typography>
                </MenuItem>
              )}
            </NavLink>
          );
        })}
      </SubMenu>
    );
  }

  // Render single menu item
  return (
    <NavLink style={{ textDecoration: "none" }} to={to || ""}>
      {({ isActive }) => (
        <MenuItem
          component="div" // Changed to div to preserve pro-sidebar animations
          active={isActive} // Explicitly set active prop
          style={{
            color: isActive
              ? theme.palette.primary.main
              : theme.palette.text.primary,
            backgroundColor: isActive
              ? theme.palette.grey[300]
              : theme.palette.background.paper,
            position: "relative",
          }}
          icon={Icon ? <Icon /> : null}
        >
          <Typography>{t(title, { defaultValue: title })}</Typography>
        </MenuItem>
      )}
    </NavLink>
  );
};

export default SidebarMenuItem;
