import { IRequestTeacher, TeachersResponse } from "types";
import axiosClient from "./axiosClient";

const teacherApi = {
  createTeacher: async (params: any) => {
    const url = "teacher";
    const res = await axiosClient.post(url, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return res.data;
  },

  updateTeacher: async (params: any) => {
    const url = `teacher/${params.id}`;
    const res = await axiosClient.patch(url, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    return res.data;
  },

  getTeacherById: async (id: number[] | string) => {
    const url = `teacher/${id}`;
    const res = await axiosClient.get(url);
    return res.data;
  },

  getTeachers: async (params?: IRequestTeacher): Promise<TeachersResponse> => {
    const url = `teacher`;
    const res = await axiosClient.get(url, { params });
    return res.data;
  },
  deleteTeacher: async (id: string) => {
    const url = `teacher/${id}`;
    const res = await axiosClient.delete(url);
    return res.data;
  },
  deleteTeachers: async (params: any) => {
    const url = `teacher/delete`;
    const res = await axiosClient.delete(url, { data: params });
    return res.data;
  },
};

export default teacherApi;
