import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CountryType } from "components/global/LanguageSelector";

export interface CommonState {
  profile: any;
  tokens: any;
  language: CountryType;
}

const initialState: CommonState = {
  profile: null,
  tokens: null,
  language: { code: "VN", language: "VI", label: "Tiếng Việt" },
};

export const commonSlices = createSlice({
  name: "common",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
    setTokens: (state, action: PayloadAction<any>) => {
      state.tokens = action.payload;
    },
    setLanguage: (state, action: PayloadAction<CountryType>) => {
      state.language = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, setTokens, setLanguage } = commonSlices.actions;

export default commonSlices.reducer;
