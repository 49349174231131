import React, { ElementType, useMemo, useRef } from "react";
import { FieldProps } from "formik";
import {
  TextField,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface InputFieldProps extends FieldProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  Icon?: ElementType;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  helperText?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  inputProps?: InputBaseComponentProps;
  defaultValue?: Record<string, any>;
  size?: "small" | "medium";
  fullWidth?: boolean;
  sx?: any;
  handleCallback?: (values: string | number) => void;
  isCurrency?: boolean;
  currencyType?: "VND" | string;
  required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  field,
  form,
  label,
  disabled = false,
  startIcon,
  endIcon,
  helperText,
  multiline = false,
  rows = 1,
  type = "text",
  size = "small",
  placeholder,
  fullWidth = true,
  inputProps,
  sx,
  handleCallback,
  isCurrency = false,
  required,
}) => {
  const { name, value } = field;
  const { errors, touched } = form;
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  // Memoized error state
  const showError = useMemo(
    () => Boolean(errors[name] && touched[name]),
    [errors[name], touched[name]]
  );

  // Format VND currency: add thousand separators and currency symbol
  const formatCurrency = (value: string | number): string => {
    if (!value && value !== 0) return "";

    // Convert to number and handle parsing
    let numValue =
      typeof value === "string"
        ? parseFloat(value.replace(/[^\d]/g, ""))
        : value;

    // Check if valid number
    if (isNaN(numValue)) return "";

    // Format with thousand separators
    const formatted = numValue.toLocaleString("vi-VN");

    return formatted;
  };

  // Remove formatting to get raw number
  const parseCurrency = (value: string): string => {
    if (!value) return "";
    return value.replace(/[^\d]/g, "");
  };

  // Calculate cursor position to maintain after formatting
  const calculateCursorPosition = (
    inputElement: HTMLInputElement,
    previousValue: string | undefined,
    newValue: string | undefined
  ): number => {
    // Handle undefined values
    const prevVal = previousValue || "";
    const newVal = newValue || "";

    const cursorPosition = inputElement.selectionStart || 0;

    // Handle cursor at the end of input specially
    const isAtEnd = cursorPosition === prevVal.length;
    if (isAtEnd) {
      return newVal.length;
    }

    // Count digits before cursor in the old value
    const previousBeforeCursor = prevVal.substring(0, cursorPosition);
    const numericCountBefore = previousBeforeCursor.replace(
      /[^\d]/g,
      ""
    ).length;

    // Find the position in the new value that has the same number of digits
    let newPosition = 0;
    let digitCount = 0;

    for (let i = 0; i < newVal.length; i++) {
      const char = newVal.charAt(i);
      if (/\d/.test(char)) {
        digitCount++;
      }
      if (digitCount >= numericCountBefore) {
        newPosition = i + 1;
        break;
      }
    }

    // Ensure we don't go past the end of the string
    return Math.min(newPosition, newVal.length);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const previousDisplayValue = event.target.defaultValue;

    if (isCurrency) {
      // Parse the raw input to get just the numbers
      const parsedValue = parseCurrency(rawValue);

      // Set the raw number value in formik
      form.setFieldValue(name, parsedValue);

      // Call the callback with the raw number value
      handleCallback?.(parsedValue);

      // Store cursor information for repositioning after formatting
      if (inputRef.current) {
        const inputElement = inputRef.current;
        const newFormattedValue = formatCurrency(parsedValue);

        // Calculate and set cursor position after React updates the DOM
        setTimeout(() => {
          if (inputElement) {
            const newPosition = calculateCursorPosition(
              inputElement,
              previousDisplayValue,
              newFormattedValue
            );
            inputElement.setSelectionRange(newPosition, newPosition);
          }
        }, 0);
      }
    } else {
      // Normal handling for non-currency fields
      form.setFieldValue(name, rawValue);
      handleCallback?.(rawValue);
    }
  };

  // Display formatted value for currency fields, raw value otherwise
  const displayValue = useMemo(() => {
    if (isCurrency && (value || value === 0)) {
      return formatCurrency(value);
    }
    return value || "";
  }, [isCurrency, value]);

  // Determine the actual input type - IMPORTANT: we use 'text' for currency fields
  const actualType = isCurrency ? "text" : type;

  // Create input props without pattern attribute for currency fields
  const finalInputProps = useMemo(() => {
    if (isCurrency) {
      // For currency fields, use inputMode but REMOVE pattern to avoid validation errors
      const { pattern, ...restInputProps } = inputProps || {};
      return {
        ...restInputProps,
        inputMode: "numeric",
      } as InputBaseComponentProps;
    }
    return inputProps;
  }, [isCurrency, inputProps]);

  // Handle translation with proper null/undefined checks
  const safeTranslate = (text: string | undefined) => {
    if (text === undefined || text === null) return undefined;
    return t(text, { defaultValue: text });
  };

  return (
    <TextField
      {...field}
      id={name}
      inputRef={inputRef}
      value={displayValue}
      label={safeTranslate(label)}
      placeholder={safeTranslate(placeholder)}
      type={actualType}
      disabled={disabled}
      required={required}
      error={showError}
      size={size}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : undefined,
        startAdornment: startIcon ? (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ) : undefined,
      }}
      helperText={
        showError
          ? safeTranslate(errors[name] as string)
          : safeTranslate(helperText)
      }
      multiline={multiline}
      rows={rows}
      fullWidth={fullWidth}
      inputProps={finalInputProps}
      sx={sx}
      onChange={handleInputChange}
    />
  );
};

export default InputField;
