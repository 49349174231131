import { Percent } from "@mui/icons-material";
import ClassIcon from "@mui/icons-material/Class";
import { Typography } from "@mui/material";

export const STUDENT_TABLE = [
  { name: "name", label: "Họ tên", fieldType: "InputField", required: true },
  { name: "parents", label: "Phụ huynh", fieldType: "InputField" },
  { name: "dob", label: "Ngày sinh", fieldType: "DatePickerField" },
  {
    name: "register_date",
    label: "Ngày đăng ký",
    fieldType: "DatePickerField",
  },
  {
    name: "status",
    label: "Trạng thái",
    fieldType: "AutocompleteField",
    options: "statusList",
  },
  { name: "phone", label: "Số điện thoại", fieldType: "InputField" },
  { name: "email", label: "Email", fieldType: "InputField" },
  { name: "facebook", label: "Facebook", fieldType: "InputField" },
  {
    name: "address",
    label: "Địa chỉ",
    fieldType: "InputField",
    multiline: true,
  },
];

export const STUDENT_FEE = [
  {
    name: "location",
    label: "Location",
    fieldType: "AutocompleteField",
    options: "locationList",
  },
  {
    name: "classes",
    label: "Class",
    options: "classList",
    multiline: true,
    fullWidth: true,
    startIcon: <ClassIcon />,
    fieldType: "MultiAutocompleteField",
  },
  {
    name: "amount",
    label: "Số tiền",
    fieldType: "InputField",
    inputProps: { min: 0, step: 50000 },
    currencyType: "VND",
    isCurrency: true,
    endIcon: <Typography variant="h5">đ</Typography>,
  },
  {
    name: "pay_date",
    label: "Ngày đóng tiền",
    fieldType: "DatePickerField",
  },
  {
    name: "unit_num",
    label: "Số buổi học",
    fieldType: "InputField",
    type: "number",
    inputProps: { min: 0 },
  },
  {
    name: "used_rest",
    label: "Số buổi đã học/còn lại",
    fieldType: "InputField",
    disabled: true,
  },
  {
    name: "discount",
    label: "Giảm giá",
    fieldType: "InputField",
    type: "number",
    endIcon: <Percent sx={{ fontSize: 16 }} />,
    inputProps: { min: 0, step: 5 },
  },
  {
    name: "gift",
    label: "Quà tặng",
    fieldType: "AutocompleteField",
    options: "giftList",
  },

  {
    name: "start_date",
    label: "Ngày bắt đầu",
    fieldType: "DatePickerField",
  },
  {
    name: "end_date",
    label: "Ngày kết thúc dự kiến",
    fieldType: "DatePickerField",
    disabled: true,
  },
  {
    name: "status",
    label: "Trạng thái",
    fieldType: "SelectField",
    options: "statusList",
  },
];
