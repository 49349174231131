import attendanceRoute from "features/portal/Attendance/route";
import locationRoute from "features/portal/Location/route";
import classRoute from "features/portal/Class/route";
import statisticRoute from "features/portal/Statistic/route";
import studentRoute from "features/portal/Student/route";
import teacherRoute from "features/portal/Teacher/route";

export default [
  ...attendanceRoute,
  ...locationRoute,
  ...classRoute,
  ...statisticRoute,
  ...studentRoute,
  ...teacherRoute,
];
