import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { Stack, LinearProgress } from "@mui/material";
import "./styles.scss";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";

import teacherApi from "api/teacherApi";
import TableData from "components/TableData";
import ActionCell from "features/components/ActionCell";
import { Header } from "components/Header";
import { useTranslation } from "react-i18next";
import { CREATE } from "constants/ConstantCommon";
import { IRequestTeacher } from "types";
import Button from "components/Button";

export default function TeacherList() {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Prepare query parameters
  const params: IRequestTeacher = { ...paginationModel };

  // Query for teachers list
  const {
    data: teachers,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["teacher", params],
    queryFn: () => teacherApi.getTeachers(params),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: 1,
    placeholderData: (previousData) => previousData,
  });

  // State to track external navigation
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  // Effect to handle external navigation
  useEffect(() => {
    setIsExternalNavigation(true);

    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  // Destructure teachers data
  const { rowCount, content: rows } = teachers ?? {};

  // Memoized add click handler
  const handleAddClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(CREATE);
    },
    [navigate]
  );

  // Comprehensive loading state
  const loading = isLoading || (isFetching && isExternalNavigation);

  // Memoized columns definition with translations and optimized widths
  const COLUMNS: GridColDef[] = [
    {
      field: "name",
      headerName: t("Full Name"),
      description: t("Full Name"),
      minWidth: 180,
      flex: 1,
    },
    {
      field: "role",
      headerName: t("Position"),
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "start_date",
      headerName: t("Start Date"),
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params: any) =>
        params?.row?.start_date
          ? dayjs(params.row.start_date).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "salary",
      headerName: t("Salary"),
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params: any) =>
        params?.row?.salary
          ? new Intl.NumberFormat("vi-VN", {
              style: "currency",
              currency: "VND",
            }).format(params.row.salary)
          : "",
    },
    {
      field: "allowance",
      headerName: t("Additional Allowance"),
      align: "center",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) =>
        params?.row?.allowance
          ? new Intl.NumberFormat("vi-VN", {
              style: "currency",
              currency: "VND",
            }).format(params.row.allowance)
          : "",
    },
    {
      field: "phone",
      headerName: t("Phone Number"),
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "seniority",
      headerName: t("Seniority"),
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      width: 120,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <ActionCell
          row={params.row}
          detailUrl={`${params.row.id}`}
          handleDelete={teacherApi.deleteTeacher}
          handleCallback={refetch}
        />
      ),
    },
  ];

  return (
    <Stack p={2} sx={{ position: "relative" }}>
      {/* Loading Indicator */}
      {isFetching && !isLoading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        />
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Header
          path={[
            {
              text: t("Teacher List"),
            },
          ]}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          disabled={loading}
          sx={{ textTransform: "none" }}
        >
          {t("Add New Teacher")}
        </Button>
      </Stack>

      <TableData
        rows={rows ?? []}
        columns={COLUMNS}
        loading={loading}
        rowCount={rowCount ?? 0}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sx={{ maxHeight: "calc(100vh - 120px)" }}
      />
    </Stack>
  );
}
