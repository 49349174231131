import { ATTENDANCE, ATTENDANCE_DETAIL } from "constants/routes";
import Attendance from ".";

const attendanceRoute = {
  path: ATTENDANCE,
  element: Attendance,
};

const attendanceDetailRoute = {
  path: ATTENDANCE_DETAIL,
  element: Attendance,
};

export default [attendanceRoute, attendanceDetailRoute];
