import React, { useState } from "react";

// Color palette for different button variants
const COLORS = {
  primary: {
    main: "rgb(25, 118, 210)",
    dark: "rgb(21, 101, 192)",
    light: "rgba(25, 118, 210, 0.08)",
    contrastText: "white",
  },
  secondary: {
    main: "rgb(156, 39, 176)",
    dark: "rgb(123, 31, 162)",
    light: "rgba(156, 39, 176, 0.08)",
    contrastText: "white",
  },
  success: {
    main: "rgb(46, 125, 50)",
    dark: "rgb(27, 94, 32)",
    light: "rgba(46, 125, 50, 0.08)",
    contrastText: "white",
  },
  error: {
    main: "rgb(211, 47, 47)",
    dark: "rgb(198, 40, 40)",
    light: "rgba(211, 47, 47, 0.08)",
    contrastText: "white",
  },
  warning: {
    main: "rgb(237, 108, 2)",
    dark: "rgb(230, 81, 0)",
    light: "rgba(237, 108, 2, 0.08)",
    contrastText: "white",
  },
  info: {
    main: "rgb(2, 136, 209)",
    dark: "rgb(1, 87, 155)",
    light: "rgba(2, 136, 209, 0.08)",
    contrastText: "white",
  },
};

export type ButtonColor =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "warning"
  | "info";
export type ButtonVariant = "contained" | "outlined" | "text";
export type ButtonSize = "small" | "medium" | "large";

// Define a type for the sx prop to allow nested objects similar to MUI
type SxProp = React.CSSProperties & {
  [key: string]: any;
  "&:hover"?: React.CSSProperties;
};

export interface PureButtonProps {
  type?: "button" | "submit" | "reset";
  color?: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  sx?: SxProp; // Add sx prop for MUI-like styling
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Button: React.FC<PureButtonProps> = ({
  type = "button",
  color = "primary",
  variant = "contained",
  size = "medium",
  fullWidth = false,
  disabled = false,
  onClick,
  children,
  className = "",
  style = {},
  sx = {},
  startIcon,
  endIcon,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  // Get color from palette
  const colorPalette = COLORS[color] || COLORS.primary;

  // Generate size-specific styles
  const sizeStyles = {
    small: {
      padding: "4px 10px",
      fontSize: "0.8125rem",
      minWidth: "64px",
      height: "30px",
    },
    medium: {
      padding: "6px 16px",
      fontSize: "0.875rem",
      minWidth: "64px",
      height: "36px",
    },
    large: {
      padding: "8px 22px",
      fontSize: "0.9375rem",
      minWidth: "75px",
      height: "42px",
    },
  }[size];

  // Generate variant-specific styles
  let variantStyles: React.CSSProperties = {};

  if (disabled) {
    variantStyles = {
      backgroundColor:
        variant === "contained" ? "rgba(0, 0, 0, 0.12)" : "transparent",
      color: "rgba(0, 0, 0, 0.26)",
      border: variant === "outlined" ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
      boxShadow: "none",
      cursor: "not-allowed",
      pointerEvents: "none",
    };
  } else {
    if (variant === "contained") {
      variantStyles = {
        backgroundColor:
          isHovering && sx["&:hover"]?.backgroundColor
            ? sx["&:hover"].backgroundColor
            : isHovering
            ? colorPalette.dark
            : sx.backgroundColor || colorPalette.main,
        color: sx.color || colorPalette.contrastText,
        border: "none",
        boxShadow: isHovering
          ? "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
          : "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      };
    } else if (variant === "outlined") {
      variantStyles = {
        backgroundColor: isHovering ? colorPalette.light : "transparent",
        color: sx.color || colorPalette.main,
        border: `1px solid ${sx.borderColor || colorPalette.main}`,
        boxShadow: "none",
      };
    } else {
      // text variant
      variantStyles = {
        backgroundColor: isHovering ? colorPalette.light : "transparent",
        color: sx.color || colorPalette.main,
        border: "none",
        boxShadow: "none",
      };
    }
  }

  // Process sx prop to extract regular CSS properties
  const sxStyles: Record<string, any> = {};
  Object.keys(sx).forEach((key) => {
    if (key !== "&:hover") {
      sxStyles[key] = sx[key];
    }
  });

  // Base styles for the button
  const baseStyles: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: "0",
    borderRadius: "4px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    minWidth: sizeStyles.minWidth,
    padding: sizeStyles.padding,
    fontSize: sizeStyles.fontSize,
    height: sizeStyles.height,
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    width: fullWidth ? "100%" : "auto",
    cursor: disabled ? "default" : "pointer",
    userSelect: "none",
    ...variantStyles,
    ...style,
    ...sxStyles,
  };

  // Icon styles
  const iconStyle: React.CSSProperties = {
    display: "inherit",
    marginRight: startIcon && children ? "8px" : "0",
    marginLeft: endIcon && children ? "8px" : "0",
    fontSize: "1.125em",
  };

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={baseStyles}
      className={className}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {startIcon && <span style={iconStyle}>{startIcon}</span>}
      {children}
      {endIcon && (
        <span
          style={{
            ...iconStyle,
          }}
        >
          {endIcon}
        </span>
      )}
    </button>
  );
};

export default Button;
