import { Dispatch, SetStateAction } from "react";
import {
  Sidebar as ProSidebar,
  Menu as SidebarMenu,
  MenuItem,
} from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SidebarMenuItem from "./SideBarMenuItem";
import useMenu, { IMenuItem } from "hooks/useMenu/useMenu";
import { useTranslation } from "react-i18next";

interface IProps {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}

const SideBar = (props: IProps) => {
  const { isCollapsed, setIsCollapsed } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  const MENU = useMenu();

  return (
    <ProSidebar
      collapsed={isCollapsed}
      width={"200px"}
      collapsedWidth={"60px"}
      backgroundColor={theme.palette.background.paper}
    >
      <SidebarMenu
        transitionDuration={400}
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                color: disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
                backgroundColor: active
                  ? theme.palette.grey[300]
                  : theme.palette.background.paper,
                "&:hover": {
                  color: `${theme.palette.primary.main} !important`,
                  backgroundColor: `${theme.palette.grey[100]} !important`,
                  fontWeight: "bold !important",
                },
              };
            }
          },
        }}
      >
        {/* LOGO AND MENU ICON */}
        <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        >
          {!isCollapsed && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">City Sports</Typography>
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <MenuOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </MenuItem>

        {MENU.map((item: IMenuItem, index: number) => (
          <SidebarMenuItem
            key={index}
            title={t(item?.title, { defaultValue: item.title })}
            to={item.path}
            Icon={item.icon}
            submenu={item.submenu}
          />
        ))}
      </SidebarMenu>
    </ProSidebar>
  );
};

export default SideBar;
