import { IRequestLocation } from "types";
import axiosClient from "./axiosClient";

const locationApi = {
  registerNewLocation: async (params: any) => {
    const url = "location";
    const res = await axiosClient.post(url, params);
    return res.data;
  },

  getLocations: async (params?: IRequestLocation) => {
    const url = "location";
    const res = await axiosClient.get(url, { params });
    return res.data;
  },

  getLocationById: async (id: string | number) => {
    const url = `location/${id}`;
    const res = await axiosClient.get(url);
    return res.data;
  },

  updateLocation: async (params: any) => {
    const url = `location/${params.id}`;
    const res = await axiosClient.patch(url, params);
    return res.data;
  },

  deleteLocation: async (id: string) => {
    const url = `location/${id}`;
    const res = await axiosClient.delete(url);
    return res.data;
  },

  getLocationStatus: async () => {
    const url = "location/status";
    const res = await axiosClient.get(url);
    return res.data;
  },

  getPayment: async () => {
    const url = "location/payment";
    const res = await axiosClient.get(url);
    return res.data;
  },
};

export default locationApi;
