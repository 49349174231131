import { ClassScheduleTable } from "./ClassScheduleTable";
import { Stack } from "@mui/material";
import ClassToolbar from "./ClassToolbar";
import { Formik } from "formik";
import dayjs from "dayjs";
import { classSchema } from "utils/validation";
import { Header } from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CREATE } from "constants/ConstantCommon";
import { CLASS_STATUS } from "constants/ConstantOptions";
import Button from "components/Button";

// Initial form values
const initialValues = {
  day_of_week: "",
  start_at: dayjs().format("HH:mm:ss"),
  type: "",
  end_at: dayjs().format("HH:mm:ss"),
  name: "",
  status: CLASS_STATUS?.[0],
  teacher: null,
  // location: null,
  location: {
    label: "All",
    code: "all",
    id: "all",
  },
};

const Class = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    const params = {
      ...values,
      teacher: values.teacher?.map((teacher: any) => teacher.id),
    };

    // Submit logic here
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={classSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, handleReset, values }) => {
        const { status, location } = values;
        const handleAddClick = () =>
          navigate(CREATE, {
            state: {
              status,
              location,
            },
          });

        return (
          <Stack
            p={2}
            spacing={2}
            component="form"
            onSubmit={handleSubmit}
            onReset={handleReset}
            position={"relative"}
          >
            <Header
              path={[
                {
                  text: t("Class List"),
                },
              ]}
            />

            <Stack
              sx={{ position: "absolute", right: 15, top: { xs: 90, md: 40 } }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                sx={{ textTransform: "none" }}
              >
                {t("Add Class")}
              </Button>
            </Stack>

            <ClassToolbar />
            <ClassScheduleTable />
          </Stack>
        );
      }}
    </Formik>
  );
};

export default Class;
