import { useFormikContext } from "formik";
import { Stack, FormControlLabel, Switch, Paper } from "@mui/material";
import { Field } from "formik";
import AutocompleteField from "custom-fields/AutocompleteField";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import classApi from "api/classApi";
import React, { useState } from "react";
import SelectField, { IOption } from "custom-fields/SelectField";
import DateCalendarField from "custom-fields/DateCalendarField";
import useOptions from "hooks/useOptions/useOptions";
import MultiAutocompleteField from "custom-fields/MultiAutocompleteField";
import teacherApi from "api/teacherApi";
import { AttendanceFormValues } from ".";

const AttendanceSearch = () => {
  const formik = useFormikContext<{
    values: AttendanceFormValues;
    handleSubmit: (values: AttendanceFormValues) => void;
  }>();
  const { values, setFieldValue } = formik;
  const { date, location, classes } = values as any;
  const dow = dayjs(date).day();

  const { LOCATIONS } = useOptions();
  const locationId =
    location?.id && location?.id === "all" ? undefined : location?.id;

  const [isClassOn, setIsClassOn] = useState(true);

  const classParams = {
    location_id: locationId,
    day_of_week: dow,
  };

  const { data: classData } = useQuery({
    queryKey: ["classes", classParams],
    queryFn: () => classApi.getClasses(classParams),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!locationId, // Only fetch when locationId is available
  });

  const CLASSES: IOption[] = classData?.content?.map((item: any) => ({
    label: `${item.name}  ${item.start_at.substring(
      0,
      5
    )} ~ ${item.end_at.substring(0, 5)}`,
    id: item.id,
    location: item.location,
  }));

  const teacherParams = {
    location_id: locationId,
  };
  const { data: teacherData } = useQuery({
    queryKey: ["teachers", teacherParams],
    queryFn: () => teacherApi.getTeachers(teacherParams),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!locationId, // Only fetch when locationId is available
  });
  const TEACHERS =
    teacherData?.content?.map((t) => ({
      label: t.name,
      id: t.id,
      code: t.id,
    })) ?? [];

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsClassOn(event.target.checked);
  };

  const handleCallBack = () => {
    setFieldValue("classes", null);
    setFieldValue("teachers", []);
  };

  return (
    <Stack spacing={2} component={Paper} elevation={8} p={2} height={"100%"}>
      <Stack direction={"row"}>
        <FormControlLabel
          control={<Switch checked={isClassOn} onChange={handleSwitch} />}
          label={isClassOn ? "Lớp Học" : "Lớp nghỉ"}
          sx={{ width: 180 }}
        />

        {!isClassOn && (
          <Field name="reason_off" component={SelectField} fullWidth />
        )}
      </Stack>

      <Field
        name="location"
        component={AutocompleteField}
        label="Cơ sở"
        options={LOCATIONS}
        handleCallback={handleCallBack}
      />

      <Field
        name="classes"
        component={AutocompleteField}
        label="Class"
        options={CLASSES}
      />

      <Field
        name="teachers"
        component={MultiAutocompleteField}
        label="Huấn Luyện Viên"
        options={TEACHERS}
      />

      <Field
        name="date"
        component={DateCalendarField}
        label="Attendance Date"
        maxDate={dayjs()}
        handleCallback={handleCallBack}
      />
    </Stack>
  );
};

export default AttendanceSearch;
