import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DesktopTimePicker, MobileTimePicker } from "@mui/x-date-pickers";
import "./styles.scss";

interface IProps {
  field: any;
  form: any;
  disabled?: any;
  type?: string;
  size?: "small" | "medium" | undefined;
  label?: string;
  fullWidth: boolean;
}

export default function TimePicker(props: IProps) {
  const {
    field,
    form,
    label,
    size = "small",
    fullWidth = true,
    disabled = false,
  } = props;
  const { name } = field;
  const { errors, touched, setFieldValue, values } = form;
  const showError = errors[name] && touched[name];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const TimePickerComponent = isSmallScreen
    ? MobileTimePicker
    : DesktopTimePicker;

  const { t } = useTranslation();

  const [value, setValue] = React.useState<Dayjs | null>(() => {
    // Initial state from form values
    if (!values[name]) return null;
    return dayjs(values[name], "HH:mm:ss");
  });

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    setFieldValue(name, newValue);
  };

  // Use useEffect to sync local state with form values
  React.useEffect(() => {
    // Update local state when form values change
    if (!values[name]) {
      setValue(null);
    } else {
      const newValue = dayjs(values[name], "HH:mm:ss");
      setValue(newValue);
    }
  }, [values[name]]);

  return (
    <Box
      sx={{ display: isSmallScreen ? "block" : { xs: "none", md: "block" } }}
    >
      <TimePickerComponent
        label={t(label as string, { defaultValue: label as string })}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        slotProps={{
          textField: {
            size: size,
            fullWidth: fullWidth,
            error: showError,
            helperText: showError && t(errors[name]),
          },
        }}
      />
    </Box>
  );
}
