import { CLASS, CLASS_DETAIL } from "constants/routes";
import Class from ".";
import { ClassDetail } from "./ClassDetail";

const classRoute = {
  path: CLASS,
  element: Class,
};

const classDetailRoute = {
  path: CLASS_DETAIL,
  element: ClassDetail,
};

export default [classRoute, classDetailRoute];
