import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "dayjs/locale/ko";
import App from "App";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { CustomPersistGate } from "components/CustomPersistGate/CustomPersistGate";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomPersistGate persistor={persistor} loading={null}>
        <App />
      </CustomPersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
