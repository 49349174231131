import axios from "axios";
import queryString from "query-string";
import { createStorage } from "../utils/LocalStorage";
import tokenApi from "./tokenApi";
import { SIGNIN } from "constants/routes";

// Create a redirect function that can be called from anywhere
export const redirectToSignIn = () => {
  const storage = createStorage("citysports");
  storage.delete(); // Clear the current user data

  // Use window.location for guaranteed navigation
  window.location.href = SIGNIN;
};

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(
  async (config: any) => {
    const storage = createStorage("citysports");
    const currentUser = storage.getAccount();
    const access_token = currentUser?.tokens?.access;

    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Comprehensive error logging
    console.error("Axios Interceptor Error:", {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      config: error.config,
    });

    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.error("401 Unauthorized Error - Attempting Token Refresh");

      originalRequest._retry = true;
      const storage = createStorage("citysports");
      const currentUser = storage.getAccount();
      const refresh_token = currentUser?.tokens?.refresh;

      if (!refresh_token) {
        console.error("No refresh token available - Unable to refresh");
        redirectToSignIn();
        return Promise.reject(error);
      }

      try {
        const param = { refresh: refresh_token };
        const tokens = await tokenApi.refresh(param);
        const access_token = tokens.access;

        currentUser.tokens.access = access_token;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        storage.set("account", currentUser);

        return axiosClient(originalRequest);
      } catch (refreshError) {
        console.error("Token Refresh Failed", refreshError);
        redirectToSignIn();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
