import { Box, Grid2 as Grid } from "@mui/material";
import { STUDENT_FEE } from "./STUDENT_TABLE";
import { Field, Formik } from "formik";
import { generateUUID, renderComponent } from "utils/common";
import { IFeeFormik } from "./Fees";
import { useQuery } from "@tanstack/react-query";
import classApi from "api/classApi";
import { IClass } from "types";
import dayjs, { Dayjs } from "dayjs";
import useOptions from "hooks/useOptions/useOptions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IOption } from "custom-fields/SelectField";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import useModalContext from "hooks/useModalContext/useModalContext";
import { feeSchema } from "utils/validation";
import { calculateEndDate } from "utils/DateHelper";

interface IProps {
  fees?: IFeeFormik[] | any[];
  fee?: IFeeFormik | any;
  push?: (fee: IFeeFormik) => void;
  remove?: (index: number) => void;
  replace?: (index: number, fee: IFeeFormik) => void;
}

const FeeDetail = (props: IProps) => {
  const { fees, fee, push, replace } = props;
  const { classes: class_ids } = fee ?? {};
  const usedUnit = fee?.unit_used ?? 0;
  const { t } = useTranslation();

  const { setOpen: setShowPopup } = useModalContext();
  const { LOCATIONS, GIFT, FEE_STATUS } = useOptions();

  // State for tracking location
  const [selectedLocation, setSelectedLocation] = useState<IOption | null>(
    null
  );
  const locationId = selectedLocation?.id;

  const { data: classes } = useQuery({
    queryKey: ["classes", selectedLocation?.id],
    queryFn: () =>
      classApi.getClasses({ location_id: locationId, status: "active" }),
    enabled: !!selectedLocation?.id,
  });

  const CLASSES: (IOption & { locationId?: string })[] = useMemo(
    () =>
      classes?.content?.map((c: IClass) => ({
        label: c.class_id,
        id: c?.id,
        dow: c?.day_of_week,
        code: c?.class_id,
      })) ?? [],
    [classes]
  );

  const { data: initClasses } = useQuery({
    queryKey: ["init classes", class_ids],
    queryFn: () => classApi.getClasses({ ids: JSON.stringify(class_ids) }),
    enabled: !!class_ids && class_ids.length > 0,
  });

  const initialValues = {
    location: null,
    classes:
      initClasses?.content?.map((c: IClass) => ({
        label: c.class_id,
        id: c?.id,
        dow: c?.day_of_week,
        code: c?.class_id,
      })) ?? [],
    amount: fee?.amount ? Number(fee?.amount) : 0,
    discount: fee?.discount ? Number(fee?.discount) : 0,
    gift: fee?.gift ? GIFT.find((g) => g.id === fee?.gift) : null,
    pay_date: fee?.pay_date ? dayjs(fee?.pay_date) : null,
    start_date: fee?.start_date ? dayjs(fee?.start_date) : null,
    end_date: fee?.end_date ? dayjs(fee?.end_date) : null,
    unit_num: fee?.unit_num ? Number(fee?.unit_num) : 0,
    used_rest: fee?.unit_num ? `${fee?.unit_used}/${fee?.unit_num}` : "",
    unit_used: fee?.unit_used ? Number(fee?.unit_used) : 0,
    status: fee?.status
      ? FEE_STATUS.find((g) => g.id === fee?.status)
      : FEE_STATUS?.[0],
  };

  const renderOptions = (type: string | undefined) => {
    switch (type) {
      case "giftList":
        return GIFT;
      case "locationList":
        return LOCATIONS;
      case "classList":
        return CLASSES;
      case "statusList":
        return FEE_STATUS;

      default:
        break;
    }
  };

  const handleClassClick = () => {
    console.log("handleClassClick");
  };

  const handleSubmit = (values: any) => {
    setShowPopup(false);
    const { status, gift, classes: selectedClasses } = values;

    const index = fees?.findIndex((f: any) => f.id === fee?.id) ?? -1;

    const params = {
      ...values,
      classes: selectedClasses?.map((c: IOption) => c.id),
      gift: gift?.id,
      status: status?.id,
      class_name: selectedClasses.map((c: IOption) => c?.label),
      gift_name: gift?.label,
      status_name: status?.label,
    };

    const feeToSave = {
      id: index === -1 ? generateUUID() : fee?.id,
      ...params,
    };

    index === -1 ? push?.(feeToSave) : replace?.(index, feeToSave);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={feeSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const {
          handleSubmit,
          handleReset,
          values,
          isSubmitting,
          setFieldValue,
        } = formikProps;
        const { location, classes, start_date, unit_num } = values;

        // Move this outside of the useEffect to ensure consistent Hook order
        const handleChangeEndChange = useCallback(
          (props: { total?: number; currentDate?: Dayjs; dows?: number[] }) => {
            const {
              total = unit_num,
              currentDate = dayjs(start_date),
              dows = classes?.map((c: IOption) => c.dow),
            } = props;

            const endDate = calculateEndDate(total, currentDate, dows);
            endDate && setFieldValue("end_date", endDate);
          },
          [unit_num, classes, start_date]
        );

        const handleChangeUsedRest = (value: string | number) => {
          const remainingUnit = isNaN(Number(value))
            ? 0
            : Number(value) - usedUnit;
          setFieldValue("used_rest", `${usedUnit}/${remainingUnit}`);
        };

        const handleCallback = (fieldName: string, value?: any | IOption[]) => {
          switch (fieldName) {
            case "unit_num":
              handleChangeUsedRest(value);
              handleChangeEndChange({ total: value });
              break;
            case "start_date":
              handleChangeEndChange({ currentDate: dayjs(value) });
              break;
            case "classes":
              console.log("classes", value);
              handleChangeEndChange({
                dows: value?.map((c: IOption) => c.dow),
              });
              break;
            default:
              break;
          }
        };

        // Move location setting to a separate useEffect
        useEffect(() => {
          if (location) {
            setSelectedLocation(location);
          }
        }, [location]);

        return (
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            component={"form"}
            onSubmit={handleSubmit}
            onReset={handleReset}
            p={1}
          >
            {STUDENT_FEE.map((item, index) => (
              <Grid key={index} size={{ xs: 12, md: 6 }}>
                <Field
                  {...item}
                  component={renderComponent(item?.fieldType)}
                  options={renderOptions(item?.options)}
                  handleEndAdornmentClick={
                    item?.name === "location" ? handleClassClick : undefined
                  }
                  handleCallback={(value?: any | IOption[]) =>
                    handleCallback(item?.name, value)
                  }
                />
              </Grid>
            ))}

            <Box
              width="100%"
              gap={2}
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: "none" }}
                type="reset"
                disabled={isSubmitting}
                onClick={() => setShowPopup(false)}
              >
                {t("Close")}
              </Button>

              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: "none" }}
                type="submit"
                disabled={isSubmitting}
              >
                {t("Save")}
              </Button>
            </Box>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default FeeDetail;
