import React, {
  ReactElement,
  ReactNode,
  Ref,
  createContext,
  forwardRef,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  POPUP_INFORM,
  POPUP_NORMAL,
  POPUP_SUBMIT,
} from "constants/ConstantCommon";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

type ModalContextType = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<any>>;
  setContent: React.Dispatch<React.SetStateAction<any>>;
  setMaxWidth?: React.Dispatch<React.SetStateAction<DialogProps["maxWidth"]>>;
  setFullWidth?: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<
    React.SetStateAction<"submit" | "normal" | "noAction" | "inform">
  >;
  setSize?: React.Dispatch<React.SetStateAction<"small" | "medium" | "large">>;
  setOkButton: React.Dispatch<React.SetStateAction<any>>;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      maxHeight: "80vh",
    },
  })
);

export const ModalContext = createContext<ModalContextType>(null!);

export const ModalProvider = (props: any) => {
  const { children } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<ReactNode>(null);
  const [content, setContent] = useState<ReactNode>(null);
  const [type, setType] = useState<"submit" | "normal" | "noAction" | "inform">(
    POPUP_NORMAL
  );
  const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [fullWidth, setFullWidth] = useState(false);
  const [size, setSize] = useState<"small" | "medium" | "large">("small");
  const [okButton, setOkButton] = useState<ReactNode>(null);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        setOpen,
        setTitle,
        setContent,
        setFullWidth,
        setType,
        setMaxWidth,
        setSize,
        setOkButton,
      }}
    >
      {children}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        classes={{ paper: classes.dialogPaper }}
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
      >
        {typeof title === "string" ? (
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20 }}>
            {title ?? ""}
          </DialogTitle>
        ) : (
          title
        )}

        <DialogContent>
          {typeof content === "string" ? (
            <DialogContentText
              sx={{ whiteSpace: "pre-line", minWidth: 300 }}
              id="alert-dialog-description"
            >
              {content}
            </DialogContentText>
          ) : (
            content
          )}
        </DialogContent>

        {(type === POPUP_NORMAL ||
          type === POPUP_INFORM ||
          type === POPUP_SUBMIT) && (
          <DialogActions>
            <Button
              variant="outlined"
              size={size}
              onClick={handleClose}
              sx={{ textTransform: "none" }}
            >
              {type === POPUP_SUBMIT ? t("Cancel") : t("Close")}
            </Button>
            {okButton}
          </DialogActions>
        )}
      </Dialog>
    </ModalContext.Provider>
  );
};
