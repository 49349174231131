import {
  Select,
  MenuItem,
  Box,
  Typography,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { changeLanguage } from "configs/i18n";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setLanguage } from "redux/slices/account";

export interface CountryType {
  code: string;
  label: string;
  language: string;
}

const countries: CountryType[] = [
  { code: "US", language: "EN", label: "English" },
  { code: "VN", language: "VI", label: "Tiếng Việt" },
];

const LanguageSelector = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.account.language);

  const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
    const selectedCountry = countries.find(
      (c) => c.code === event.target.value
    );

    if (selectedCountry) {
      dispatch(setLanguage(selectedCountry));
    }
  };

  // Initialize language from persisted state or browser preference
  useEffect(() => {
    if (!language?.code) {
      // No language in Redux, set a default based on browser
      const browserLang = navigator.language.toLowerCase();
      const defaultCode = browserLang.includes("vi") ? "VN" : "US";
      const defaultCountry =
        countries.find((c) => c.code === defaultCode) || countries[0];

      console.log("Setting default language:", defaultCountry);
      defaultCountry && dispatch(setLanguage(defaultCountry));
    } else {
      // Ensure i18n is synced with Redux state
      console.log("Syncing i18n with Redux language:", language.language);
      changeLanguage(language.language);
    }
  }, []); // Empty dependency array - run once on mount

  // React to language changes in Redux
  useEffect(() => {
    console.log("Language changed in Redux:", language);
    if (language?.language) {
      changeLanguage(language.language);
    }
  }, [language]);

  return (
    <FormControl
      size="small"
      sx={{
        display: { xs: "none", md: "block" },
        minWidth: 120,
      }}
    >
      <Select
        value={language?.code || countries[0]?.code}
        onChange={handleChangeLanguage}
        sx={{
          color: "#fff",
          "& .MuiSelect-icon": { color: "#fff" },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
        }}
        renderValue={(selected: string) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${selected.toLowerCase()}.png`}
              alt=""
              style={{ marginRight: 8 }}
            />
            <Typography>
              {countries.find((c) => c.code === selected)?.label}
            </Typography>
          </Box>
        )}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                alt=""
                style={{ marginRight: 8 }}
              />
              <Typography>{country.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
