import { Box } from "@mui/material";
import Button from "components/Button";
import React from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useTranslation } from "react-i18next";

interface IProps {
  disabled?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUploadButton = (props: IProps) => {
  const { disabled, handleChange } = props;
  const { t } = useTranslation();

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent any form submission
    e.stopPropagation(); // Stop event propagation

    // Try to trigger click programmatically
    if (fileInputRef.current) {
      // For mobile Safari, we need to focus before clicking
      fileInputRef.current.focus();
      fileInputRef.current.click();
    }
  };

  // Mobile devices often need a more direct interaction
  // with the file input element for security reasons
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      {/* The visible button */}
      <Button
        variant="contained"
        disabled={disabled}
        endIcon={<PhotoCamera />}
        sx={{
          textTransform: "none",
          position: "relative",
          zIndex: 1,
        }}
        onClick={handleButtonClick}
        type="button"
      >
        {t("Choose Image")}
      </Button>

      {/* The file input that's styled to be on top but invisible */}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          cursor: "pointer",
          zIndex: 2, // Higher than the button
        }}
      />
    </Box>
  );
};

export default FileUploadButton;
