import i18n, { CustomTypeOptions, TOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { createTheme } from "@mui/material/styles";
import translationEN from "../assets/languages/en.json";
import translationVI from "../assets/languages/vi.json";

// the translations
const resources = {
  EN: {
    translation: translationEN,
  },
  VI: {
    translation: translationVI,
  },
};

const applicationNamespace = "translation";

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: typeof applicationNamespace;
    resources: {
      [applicationNamespace]: typeof translationVI;
    };
  }
}

let muiTheme = createTheme(); // Default theme, will update dynamically

export const getMuiTheme = () => muiTheme;

// Function to get initial language from Redux persisted state
const getInitialLanguage = () => {
  try {
    // Check Redux persisted state first
    const persistedState = localStorage.getItem("persist:root");
    if (persistedState) {
      const parsedState = JSON.parse(persistedState);
      if (parsedState.account) {
        const accountState = JSON.parse(parsedState.account);
        if (accountState.language && accountState.language.language) {
          return accountState.language.language;
        }
      }
    }
  } catch (e) {
    console.warn("Could not get language from persisted state:", e);
  }
  return "EN"; // Default fallback
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    resources,
    lng: getInitialLanguage(),
    fallbackLng: "EN",
    defaultNS: applicationNamespace,
    ns: [applicationNamespace],
    compatibilityJSON: "v4",
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
    interpolation: { escapeValue: false },
  });

type TranslationKeys = keyof CustomTypeOptions["resources"]["translation"];

export function translate(
  name: TranslationKeys,
  params?: TOptions<{ [key: string]: any }>
): string {
  return i18n.t(name, { ...params, defaultValue: name });
}

export const changeLanguage = (languageKey: string) => {
  console.log("changeLanguage called with:", languageKey);
  localStorage.setItem("i18nextLng", languageKey);
  i18n.changeLanguage(languageKey);
};

export default i18n;
