import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2 as Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import { STUDENT_TABLE } from "./STUDENT_TABLE";
import { Field } from "formik";
import { renderComponent } from "utils/common";
import { IOption } from "custom-fields/SelectField";
import { STUDENT_STATUS } from "constants/ConstantCommon";

const STATUS: IOption[] = Array.from(STUDENT_STATUS).map(([key, value]) => {
  return { label: value, id: key, code: key };
});

const StudentInfo = (props: any) => {
  const { isLoading, disabled, sx } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const renderOptions = (type: string | undefined) => {
    switch (type) {
      case "statusList":
        return STATUS;
      default:
        break;
    }
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        ...sx,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: theme.palette.action.hover,
          "& .MuiAccordionSummary-content": {
            margin: theme.spacing(1, 0),
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <InfoIcon color="primary" />
          <Typography variant="h6" fontWeight="bold">
            {t("Student Information")}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          p: 2,
          maxHeight: 500,
          overflowY: "auto",
          width: "100%",
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid display="flex" alignItems="center" gap={2} width="100%">
            {isLoading ? (
              <Skeleton variant="circular" width={100} height={100} />
            ) : (
              <Field
                name="avatar"
                component={renderComponent("ImageUploadField")}
                disabled={disabled}
              />
            )}
          </Grid>
          {STUDENT_TABLE.map((item, index) => (
            <Grid key={index} size={{ xs: 12, md: 6, lg: 4 }} width="100%">
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={37} />
              ) : (
                <Field
                  {...item}
                  component={renderComponent(item?.fieldType)}
                  options={renderOptions(item?.options)}
                  disabled={disabled}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default StudentInfo;
