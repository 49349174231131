import { IOption } from "custom-fields/SelectField";
import * as Yup from "yup";

// Updated phone regex with more precise pattern for validation
const phoneRegExp =
  /^(\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Không được để trống"),
  password: Yup.string().required("Không được để trống"),
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Mật khẩu phải nhiều hơn 6 kí tự")
    .required("Không được để trống"),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password")],
    "Mật khẩu không trùng khớp"
  ),
});

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email không hợp lệ")
    .required("Không được để trống"),
  userName: Yup.string().required("Không được để trống"),
  password: Yup.string()
    .min(6, "Mật khẩu phải nhiều hơn 6 kí tự")
    .required("Không được để trống"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Mật khẩu không trùng khớp")
    .required("Không được để trống"),
});

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email không hợp lệ")
    .required("Không được để trống"),
});

// Enhanced phone validation with specific warnings
const phoneValidation = Yup.string()
  .nullable()
  .transform((value) => (value === "" ? null : value))
  .test({
    name: "phone-validation",
    skipAbsent: true,
    test(value, ctx) {
      // If empty string or null/undefined, return true (valid)
      if (!value || value.trim() === "") {
        return true;
      }

      // Remove all non-digit characters for length check
      const digitsOnly = value.replace(/\D/g, "");

      // Specific Vietnamese phone number validation
      const vietnamesePhoneRegExp = /^(0|\+84)(3|5|7|8|9)\d{8}$/;

      // Check if matches Vietnamese phone number format
      if (!vietnamesePhoneRegExp.test(digitsOnly)) {
        return ctx.createError({
          message:
            "Số điện thoại không đúng định dạng Việt Nam. Ví dụ: 0912345678, +84912345678",
        });
      }

      // Additional length check (Vietnamese mobile numbers are exactly 10 digits after country code)
      if (digitsOnly.length !== 10) {
        return ctx.createError({
          message: "Số điện thoại Việt Nam phải có 10 chữ số",
        });
      }

      // Check for invalid characters (more lenient to allow formatting)
      if (/[^0-9+\-\s.()\[\]]/.test(value)) {
        return ctx.createError({
          message: "Số điện thoại chứa ký tự không hợp lệ",
        });
      }

      return true;
    },
  });

// Optional: You can also create a separate regex for reference
const vietnamesePhoneRegExp = /^(0|\+84)(3|5|7|8|9)\d{8}$/;

const StudentFormSchema = Yup.object().shape({
  name: Yup.string().required("Không được để trống"),
  phone: phoneValidation,
});

const TeacherFormSchema = Yup.object().shape({
  name: Yup.string().required("Không được để trống"),
  phone: Yup.string()
    .matches(phoneRegExp, "Số điện thoại không hợp lệ")
    .required("Không được để trống"),
});

const AttendanceSchema = Yup.object().shape({
  classes: Yup.object().required("Không được để trống").nullable(),
  teachers: Yup.array().min(1, "Không được để trống").nullable(),
  location: Yup.object().required("Không được để trống").nullable(),
  date: Yup.date().required("Không được để trống"),
});

export const locationSchema = Yup.object().shape({});
export const classSchema = Yup.object().shape({});
export const classFormSchema = Yup.object().shape({
  location: Yup.object()
    .required("Không được để trống")
    .test(
      "not-all-location",
      "Vui lòng chọn địa điểm cụ thể",
      (value: IOption) => {
        if (!value) return false;
        return !(
          value.id === "all" &&
          value.code === "all" &&
          value.label === "All"
        );
      }
    )
    .nullable(),
  name: Yup.string().required("Không được để trống"),
});

export const feeSchema = Yup.object().shape({});

export {
  LoginSchema,
  PasswordSchema,
  EmailSchema,
  SignUpSchema,
  StudentFormSchema,
  TeacherFormSchema,
  AttendanceSchema,
};
