import { LOCATION, LOCATION_DETAIL } from "constants/routes";
import Location from ".";
import { LocationDetail } from "./LocationDetail/LocationDetail";

const catalogRoute = {
  path: LOCATION,
  element: Location,
};

const locationDetailRoute = {
  path: LOCATION_DETAIL,
  element: LocationDetail,
};

export default [catalogRoute, locationDetailRoute];
