import { useQuery } from "@tanstack/react-query";
import locationApi from "api/locationApi";
import {
  DOW,
  CLASS_TYPE,
  CLASS_STATUS,
  GIFT,
  FEE_STATUS,
} from "constants/ConstantOptions";
import { IOption } from "custom-fields/SelectField";
import { useMemo } from "react";

export default function useOptions() {
  // Queries
  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: () => locationApi.getLocations(),
  });

  const LOCATIONS: IOption[] = useMemo(
    () =>
      locations?.content?.map((item: any) => ({
        label: item.name,
        code: item.id,
        id: item.id,
      })) || [],
    [locations]
  );

  return { LOCATIONS, CLASS_TYPE, CLASS_STATUS, DOW, GIFT, FEE_STATUS };
}
