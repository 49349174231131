import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * A simple ToastContainer component for displaying toast notifications
 */
export default function BasicToastContainer() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={2}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      theme="light"
      style={{ width: "auto", maxWidth: "400px" }}
    />
  );
}
