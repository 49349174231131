export const CLASS_TABLE_ROW = [
  { name: "class_id", label: "Mã lớp", fieldType: "InputField" },
  { name: "name", label: "Tên lớp", fieldType: "InputField" },
  {
    name: "day_of_week",
    label: "thứ",
    fieldType: "SelectField",
    option: "dowList",
  },
  { name: "start_at", label: "Giờ bắt đầu", fieldType: "TimePickerField" },
  { name: "end_at", label: "Giờ kết thúc", fieldType: "TimePickerField" },
  {
    name: "type",
    label: "Loại lớp",
    fieldType: "SelectField",
    option: "typeList",
  },
  // {
  //   name: "is_open",
  //   label: "Trạng thái",
  //   fieldType: "SelectField",
  //   option: "statusList",
  // },
  {
    name: "teachers",
    label: "Huấn luyện viên",
    fieldType: "MultiAutocompleteField",
    option: "teacherList",
  },
];
