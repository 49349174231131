import React, { useState, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { MobileDatePicker, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface IProps {
  field: {
    name: string;
    value: any;
    onChange: (event: React.ChangeEvent<any>) => void;
    onBlur: (event: React.FocusEvent<any>) => void;
  };
  form: {
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
    touched: any;
  };
  disabled?: boolean;
  type?: string;
  size?: "small" | "medium" | undefined;
  label?: string;
  fullWidth?: boolean;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  handleCallback?: (values: string | Dayjs) => void;
}

export const viLocaleText = {
  cancelButtonLabel: "Hủy",
  okButtonLabel: "Chọn",
  clearButtonLabel: "Xóa",
  todayButtonLabel: "Hôm nay",
  datePickerToolbarTitle: "Chọn ngày",
  calendarWeekNumberHeaderLabel: "Tuần",
  calendarWeekNumberText: (weekNumber: number) => `Tuần ${weekNumber}`,
};

export default function DatePickerField(props: IProps) {
  const {
    field,
    form,
    size = "small",
    label,
    fullWidth = true,
    disabled,
    maxDate,
    minDate,
    handleCallback,
  } = props;
  const { name, value: fieldValue } = field;
  const { setFieldValue, errors, touched } = form;

  const showError = useMemo(
    () => Boolean(errors[name] && touched[name]),
    [errors[name], touched[name]]
  );

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Use simpler Vietnamese format with slashes which is more common
  const format = i18n.language === "vi" ? "DD/MM/YYYY" : "DD-MM-YYYY";

  // Set the locale on component mount and when language changes
  useEffect(() => {
    const currentLocale = i18n.language === "vi" ? "vi" : "en";
    dayjs.locale(currentLocale);
  }, [i18n.language]);

  // Initialize value with proper conversion
  const [value, setValue] = useState<Dayjs | null>(() => {
    // Handle various input formats
    if (!fieldValue) return null;

    // If it's already a dayjs object, return it
    if (dayjs.isDayjs(fieldValue)) return fieldValue;

    // Try parsing the input value
    const parsedValue = dayjs(fieldValue);
    return parsedValue.isValid() ? parsedValue : null;
  });

  // Update value when formik values change
  useEffect(() => {
    if (fieldValue) {
      const newValue = dayjs(fieldValue);
      if (newValue.isValid() && (!value || !value.isSame(newValue))) {
        setValue(newValue);
      }
    } else if (!fieldValue && value) {
      setValue(null);
    }
  }, [fieldValue]);

  const handleChange = (newValue: Dayjs | null) => {
    // Ensure newValue is converted to a valid format
    if (newValue) {
      const formattedValue = newValue.format("YYYY-MM-DD");
      setValue(newValue);
      setFieldValue(name, formattedValue);
      handleCallback?.(formattedValue);
    } else {
      setValue(null);
      setFieldValue(name, null);
    }
  };

  const commonProps = {
    value: value,
    onChange: handleChange,
    localeText: i18n.language === "vi" ? viLocaleText : undefined,
    format: format,
    disabled: disabled,
    maxDate: maxDate,
    minDate: minDate,
  };

  if (isSmallScreen)
    return (
      <MobileDatePicker
        {...commonProps}
        label={t(label as string, { defaultValue: label as string })}
        slotProps={{
          textField: {
            size: size,
            fullWidth: true,
            error: showError,
            helperText: showError && t(errors[name]),
          },
        }}
      />
    );

  return (
    <DatePicker
      {...commonProps}
      label={t(label as string, { defaultValue: label as string })}
      slotProps={{
        textField: {
          size: size,
          fullWidth: fullWidth,
          error: showError,
          helperText: showError && t(errors[name]),
        },
      }}
    />
  );
}
