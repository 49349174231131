import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Link,
  Paper,
  Grid2 as Grid,
  Typography,
  Container,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import KeyIcon from "@mui/icons-material/Key";
import { Formik, Field } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import GoogleSignin from "./GoodleSignIn";
import FacebookSignin from "./FacbookSignIn";
import "./styles.scss";
import { userApi } from "api/tokenApi";
import { createStorage } from "utils/LocalStorage";
import InputField from "custom-fields/InputField";
import PasswordField from "custom-fields/PasswordField";
import { LoginSchema } from "utils/validation";
import Button from "components/Button";
import { Images } from "assets/images";

export const SignIn = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const login = useMutation({
    mutationFn: (credentials: { username: string; password: string }) =>
      userApi.login(credentials),
    onSuccess: (currentUser) => {
      const storage = createStorage("citysports");
      storage.set("account", currentUser);
      queryClient.clear();
      navigate("/portal", { replace: true });
    },
    onError: (err: any) => {
      const { data } = err.response;
      alert(JSON.stringify(data));
    },
  });

  const handleSubmit = (values: { username: string; password: string }) => {
    login.mutate(values);
  };

  const handleClickResetPassword = useCallback(
    () => navigate("/auth/email-reset", { replace: true }),
    [navigate]
  );

  const handleClickSignUp = useCallback(
    () => navigate("/auth/sign-up", { replace: true }),
    [navigate]
  );

  const initialValues = {
    username: "",
    password: "",
  };

  return (
    <Container className="h-screen p-4">
      <Grid
        container
        component={Paper}
        elevation={8}
        className="h-full justify-center align-center overflow-hidden"
      >
        {/* Left side - Background Image */}
        <Grid
          width="100%"
          size={{ xs: 0, sm: 6 }}
          sx={{
            display: { xs: "none", md: "flex" },
            backgroundImage: `url(https://picsum.photos/1200/800?random=${Math.random()})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: 1,
            },
          }}
          component={Paper}
          elevation={6}
          square
        >
          <Box className="relative z-10 flex flex-col items-center justify-center w-full h-full text-white p-8">
            <Typography variant="h3" component="h1" className="font-bold mb-4">
              City Sports
            </Typography>
            <Typography variant="h6" component="p" className="text-center">
              Quản lý hoạt động thể thao của bạn một cách hiệu quả
            </Typography>
          </Box>
        </Grid>

        {/* Right side - Login Form */}
        <Grid
          width="100%"
          size={{ xs: 12, md: 6 }}
          component={Paper}
          elevation={0}
          className="flex flex-col justify-center items-center px-4 sm:px-8 py-12"
          position={"relative"}
        >
          <Box className="w-full max-w-md">
            <Box className="flex flex-col items-center mb-6">
              <Avatar
                src={Images.Logo}
                sx={{ width: 100, height: 70, mb: 2, objectFit: "cover" }}
                variant="square"
              />
              <Typography component="h1" variant="h4" className="font-bold">
                Đăng nhập
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="mt-2"
              >
                Đăng nhập để quản lý thông tin thể thao của bạn
              </Typography>
            </Box>

            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <Box
                  onSubmit={handleSubmit}
                  component="form"
                  className="w-full"
                >
                  <Stack spacing={3} className="mb-6">
                    <Field
                      name="username"
                      component={InputField}
                      label="Tài Khoản"
                      size="large"
                      endIcon={<ContactMailIcon />}
                      helperText="Sử dụng địa chỉ email hoặc tên tài khoản của bạn"
                    />

                    <Field
                      name="password"
                      component={PasswordField}
                      label="Mật khẩu"
                      placeholder="Nhập mật khẩu"
                      Icon={KeyIcon}
                      size="large"
                    />
                  </Stack>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={login?.isPending}
                    sx={{
                      height: "52px",
                      fontSize: "16px",
                      fontWeight: 600,
                      textTransform: "none",
                      mt: 2,
                    }}
                  >
                    {login?.isPending ? "Đang đăng nhập..." : "Đăng nhập"}
                  </Button>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    className="mt-4 mb-8"
                  >
                    <Link
                      href="#"
                      onClick={handleClickResetPassword}
                      className="text-sm hover:underline"
                      sx={{ color: "primary.main" }}
                    >
                      Quên mật khẩu?
                    </Link>
                    <Link
                      href="#"
                      onClick={handleClickSignUp}
                      className="text-sm hover:underline"
                      sx={{ color: "primary.main" }}
                    >
                      Đăng ký tài khoản
                    </Link>
                  </Stack>

                  <Divider className="mb-6">
                    <Typography variant="body2" color="text.secondary">
                      Hoặc đăng nhập với
                    </Typography>
                  </Divider>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={{ xs: 2, sm: 5 }}
                  >
                    <GoogleSignin />
                    <FacebookSignin />
                  </Stack>
                </Box>
              )}
            </Formik>

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                textAlign: "center",
                py: 2,
                backgroundColor: "rgba(255,255,255,0.05)",
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  opacity: 0.7,
                  fontWeight: 300,
                  letterSpacing: 0.5,
                  transition: "opacity 0.3s ease",
                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
              >
                © {new Date().getFullYear()} City Sports. Bảo lưu mọi quyền.
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  mt: 0.5,
                  color: "text.disabled",
                  fontSize: "0.6rem",
                }}
              >
                Phiên bản {import.meta.env.VITE_APP_VERSION || "1.0.0"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
