import { IRequestAttendance } from "types";
import axiosClient from "./axiosClient";

const attendanceApi = {
  registerNewAttendance: async (params: any) => {
    const url = "attendance";
    const res = await axiosClient.post(url, params);
    return res.data;
  },
  getAttendance: async (params: IRequestAttendance) => {
    const url = `attendance`;
    const res = await axiosClient.get(url, { params });
    return res.data;
  },
  updateAttendance: async (params: any) => {
    const url = `attendance/${params?.id}`;
    const res = await axiosClient.patch(url, params);
    return res.data;
  },
};

export default attendanceApi;
