import { DAY_OF_WEEK as DAY_OF_WEEK_MAP } from "constants/ConstantCommon";
import { IOption } from "custom-fields/SelectField";

export const CLASS_TYPE = [
  { label: "A", id: "A", code: "A" },
  { label: "B", id: "B", code: "B" },
  { label: "C", id: "C", code: "C" },
];

export const DOW = Array.from(DAY_OF_WEEK_MAP)?.map(([key, value]) => ({
  label: value,
  id: key,
  code: key,
}));

export const CLASS_STATUS: IOption[] = [
  {
    code: "active",
    label: "Open Class",
    id: "active",
  },
  {
    code: "inactive",
    label: "Close Class",
    id: "inactive",
  },
];

export const GIFT: IOption[] = [
  { label: "Đồng phục", id: "uniform" },
  { label: "Bóng", id: "ball" },
  { label: "Ba lô", id: "backpack" },
  { label: "Băng tay chân bảo vệ", id: "gear" },
  { label: "Quà tặng khác", id: "other" },
];

export const FEE_STATUS: IOption[] = [
  {
    label: "Đang xử lý",
    id: "PENDING",
    code: "Pending",
  },
  {
    label: "Đã đóng tiền",
    id: "PAID",
    code: "Paid",
  },
  {
    label: "Hết hạn học phí",
    id: "OVERDUE",
    code: "Overdue",
  },
];
