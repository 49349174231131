import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useTheme } from "@mui/material";
import { userApi } from "api/tokenApi";
import { createStorage } from "utils/LocalStorage";

const GoogleSignin = () => {
  const theme = useTheme();
  const clientId = String(import.meta.env.VITE_GOOGLE_CLIENT_ID);
  const navigate = useNavigate();

  const login = useMutation({
    mutationFn: userApi.loginGoogle,
    onSuccess: (response: any) => {
      const { data } = response;
      const storage = createStorage("citysports");
      storage.set("account", data);
      navigate("/portal", { replace: true });
    },
    onError: (error: any) => {
      alert(error.response.data.message);
    },
  });

  const onSuccess = (res: any) => {
    const { credential } = res;
    if (credential) {
      const auth_token = { auth_token: credential };
      login.mutate(auth_token as any);
    }
  };

  const onFailure = () => {
    console.log("GoogleSignin", "[Login Failed]");
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onFailure}
        locale="vi"
        auto_select
        type="standard"
        theme="outline"
        size="large"
        text="continue_with"
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleSignin;
