import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { PERMISSION_LIST, ROLE_LIST } from "constants/ConstantCommon";
import {
  ATTENDANCE,
  LOCATION,
  CLASS,
  STATISTIC,
  STUDENT,
  TEACHER,
} from "constants/routes";

export interface IMenuItem {
  title: string;
  path?: string;
  icon?: any;
  submenu?: Array<{
    title: string;
    path: string;
    icon?: any;
  }>;
}

type RoleGroupItem = {
  groupName: ROLE_LIST;
  permission: PERMISSION_LIST[];
};

const LIST_GROUP: RoleGroupItem[] = [
  {
    groupName: ROLE_LIST.SUPER_ADMIN,
    permission: [
      PERMISSION_LIST.Appointment,
      PERMISSION_LIST.Dashboard,
      PERMISSION_LIST.ServiceSetting,
      PERMISSION_LIST.CounselorList,
      PERMISSION_LIST.PatientList,
      PERMISSION_LIST.Setting,
      PERMISSION_LIST.OrganizationSetting,
      PERMISSION_LIST.User,
      PERMISSION_LIST.Scheduling,
    ],
  },
  {
    groupName: ROLE_LIST.COORDINATOR,
    permission: [
      PERMISSION_LIST.Appointment,
      PERMISSION_LIST.Dashboard,
      PERMISSION_LIST.ServiceSetting,
      PERMISSION_LIST.CounselorList,
      PERMISSION_LIST.PatientList,
      PERMISSION_LIST.Setting,
      PERMISSION_LIST.User,
      PERMISSION_LIST.Scheduling,
    ],
  },
  {
    groupName: ROLE_LIST.COUNSELOR,
    permission: [
      PERMISSION_LIST.Appointment,
      PERMISSION_LIST.WaitingRoom,
      PERMISSION_LIST.PatientList,
      PERMISSION_LIST.Profile,
      // PERMISSION_LIST.Scheduling,
      PERMISSION_LIST.DoctorScheduling,
    ],
  },
];

export default function useMenu() {
  // const token = useAppSelector((state) => state?.userInformationReducer?.token);
  // const userInfo = useAppSelector(
  //   (state) => state.userInformationReducer?.userInfo
  // );
  // const roles = userInfo?.types;

  // const id = userInfo?.resourceId;

  const MENUS: IMenuItem[] = [
    {
      title: "Attendance",
      path: ATTENDANCE,
      icon: GradingOutlinedIcon,
    },
    {
      title: "Courts",
      path: LOCATION,
      icon: LocationOnOutlinedIcon,
    },
    {
      title: "Class",
      path: CLASS,
      icon: ClassOutlinedIcon,
    },
    {
      title: "Statistics",
      path: STATISTIC,
      icon: AssessmentOutlinedIcon,
    },
    {
      title: "Student",
      path: STUDENT,
      icon: FaceOutlinedIcon,
    },
    {
      title: "Teacher",
      path: TEACHER,
      icon: BadgeOutlinedIcon,
    },
  ];

  // const menu = useMemo(() => {
  //   const res: Array<MenuItemtype> = [];
  //   LIST_GROUP.forEach((item) => {
  //     if (roles?.includes(item.groupName)) {
  //       MENUS.forEach((menuItem) => {
  //         item.permission.forEach((i) => {
  //           if (menuItem.title === i) {
  //             res.push(menuItem);
  //           }
  //         });
  //       });
  //     }
  //   });
  //   if (res.length === 0) {
  //     MENUS.pop();
  //     return MENUS;
  //   }
  //   return res;
  // }, [MENUS, token]);

  return MENUS;
}
