import forgotPasswordRoute from "features/auth/ForgotPassword/route";
import resetPasswordRoute from "features/auth/ResetPassword/route";
import signInRoute from "features/auth/SignIn/route";
import signUpRoute from "features/auth/SignUp/route";

export default [
  forgotPasswordRoute,
  resetPasswordRoute,
  signInRoute,
  signUpRoute,
];
