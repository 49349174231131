import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate, To } from "react-router-dom";
import { styled } from "@mui/material/styles";

interface IProps {
  path: IBreadcrumbs[];
  disabled?: boolean;
}

interface IBreadcrumbs {
  text: string;
  route?: string | number;
}

// Create a custom Link component with disabled prop
interface NavigationLinkProps extends React.ComponentProps<typeof Link> {
  disabled?: boolean;
}

// Use a wrapper function component directly instead of trying to use the styled component in JSX
const NavigationLink = (props: NavigationLinkProps) => {
  const { disabled, ...rest } = props;

  // Apply styles through a custom sx prop
  return (
    <Link
      {...rest}
      sx={{
        cursor: disabled ? "default" : "pointer",
        fontWeight: 500,
        transition: "color 0.2s ease-in-out",
        "&:hover": {
          color: disabled ? "inherit" : (theme) => theme.palette.primary.main,
        },
        display: "flex",
        alignItems: "center",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
        textDecoration: disabled ? "none" : "underline",
        ...(props.sx || {}),
      }}
    />
  );
};

// Do the same for CurrentPage
const CurrentPage = (props: React.ComponentProps<typeof Typography>) => {
  return (
    <Typography
      {...props}
      sx={{
        fontWeight: 600,
        color: (theme) => theme.palette.text.primary,
        ...(props.sx || {}),
      }}
    />
  );
};

export const Header = (props: IProps) => {
  const { path, disabled = false } = props;
  const navigate = useNavigate();

  const breadcrumbs = path?.filter(
    (breadcrumb: IBreadcrumbs) => breadcrumb.route
  );

  const title = path?.filter(
    (breadcrumb: IBreadcrumbs) => !breadcrumb.route
  )?.[0]?.text;

  const handleNavigation = (route: To | undefined) => {
    if (route && !disabled) {
      navigate(route, {
        state: {
          forceRefresh: true,
        },
      });
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb: IBreadcrumbs, index: number) => (
        <NavigationLink
          key={index}
          underline={disabled ? "none" : "hover"}
          color="inherit"
          onClick={() => handleNavigation(breadcrumb.route as any)}
          disabled={disabled}
        >
          {breadcrumb.text}
        </NavigationLink>
      ))}
      {title && <CurrentPage>{title}</CurrentPage>}
    </Breadcrumbs>
  );
};
