import { ModalContext } from "components/ModalProvider/ModalProvider";
import { useContext } from "react";

export default function useModalContext() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext Error");
  }
  return context;
}
