import { useState, useEffect } from "react";
import {
  Box,
  Grid2 as Grid,
  Paper,
  Skeleton,
  LinearProgress,
} from "@mui/material";
import { FastField, Formik, FormikHelpers } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import LOCATION_TABLE from "./LOCATION_TABLE";
import InputField from "custom-fields/InputField";
import { toast } from "react-toastify";
import locationApi from "api/locationApi";
import { locationSchema } from "utils/validation";
import { CREATE } from "constants/ConstantCommon";
import { Header } from "components/Header";
import { useTranslation } from "react-i18next";
import { LOCATION } from "constants/routes";
import Button from "components/Button";

export const LocationDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State to track external navigation
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  const {
    data: locationData,
    isLoading: locationIsLoading,
    isFetching: locationIsFetching,
  } = useQuery({
    queryKey: ["location", id],
    queryFn: () => id && id !== CREATE && locationApi.getLocationById(id),
    enabled: !!id && id !== CREATE,
    // Ensure refetching when returning to the page
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  // Add effect to handle external navigation
  useEffect(() => {
    // Set flag when navigating back to this page
    setIsExternalNavigation(true);

    // Reset flag after initial load
    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  const initialValues = {
    name: locationData?.name ?? "",
    address: locationData?.address ?? "",
    contact1: locationData?.contact1 ?? "",
    phone1: locationData?.phone1 ?? "",
    contact2: locationData?.contact2 ?? "",
    phone2: locationData?.phone2 ?? "",
    contact3: locationData?.contact3 ?? "",
    phone3: locationData?.phone3 ?? "",
  };

  const registerNewLocation = useMutation({
    mutationFn: locationApi.registerNewLocation,
    onSuccess: () => {
      toast.success(t("Register location successfully"));
      navigate(LOCATION);
    },
    onError: () => {
      toast.error(t("Registration failed"));
    },
  });

  const updateLocation = useMutation({
    mutationFn: locationApi.updateLocation,
    onSuccess: (response) => {
      toast.success(`${t("Updated location information")} ${response.name}`);
      navigate(LOCATION);
    },
    onError: () => {
      toast.error(t("Please check location code"));
    },
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      if (id === CREATE) {
        await registerNewLocation.mutateAsync(values);
      } else {
        await updateLocation.mutateAsync({ ...values, id });
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  // Determine if location data is being fetched
  const isLoadingLocation =
    locationIsLoading || (locationIsFetching && isExternalNavigation);

  // Combine submission states
  const isSubmittingOrFetching =
    registerNewLocation.isPending ||
    updateLocation.isPending ||
    locationIsFetching;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={locationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, handleReset, isSubmitting }) => (
        <Box
          p={2}
          component="form"
          onSubmit={handleSubmit}
          onReset={handleReset}
          sx={{ position: "relative" }}
        >
          {/* Fetching/Submitting Progress Indicator */}
          {isSubmittingOrFetching && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
              }}
            />
          )}

          <Header
            path={[
              {
                text: t("Location List"),
                route: LOCATION,
              },
              {
                text: t("Location"),
              },
            ]}
            disabled={isSubmitting || isSubmittingOrFetching}
          />

          <Grid
            container
            component={Paper}
            elevation={8}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
            p={2}
            sx={{
              opacity: isSubmittingOrFetching ? 0.7 : 1,
              pointerEvents: isSubmittingOrFetching ? "none" : "auto",
            }}
          >
            {LOCATION_TABLE.map((item, index) => (
              <Grid key={index} width="100%" size={{ xs: 12, md: 6, lg: 3 }}>
                {isLoadingLocation ? (
                  <Skeleton variant="rectangular" width="100%" height={37.5} />
                ) : (
                  <FastField
                    name={item?.name}
                    component={InputField}
                    label={item?.label}
                    disabled={isSubmitting || isSubmittingOrFetching}
                  />
                )}
              </Grid>
            ))}
          </Grid>

          {!isLoadingLocation && (
            <Box
              width="100%"
              gap={2}
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: "none" }}
                type="reset"
                disabled={isSubmitting || isSubmittingOrFetching}
              >
                {t("Reset")}
              </Button>

              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: "none" }}
                type="submit"
                disabled={isSubmitting || isSubmittingOrFetching}
              >
                {t("Save")}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default LocationDetail;
