import { Grid2 as Grid } from "@mui/material";
import RadioGroupField from "custom-fields/RadioGroupField";
import SelectField from "custom-fields/SelectField";
import { Field } from "formik";
import useOptions from "hooks/useOptions/useOptions";

interface IProps {
  loading?: boolean;
  disabled?: boolean;
}

const ClassToolbar = (props: IProps) => {
  const { disabled } = props;
  const { LOCATIONS, CLASS_STATUS: STATUS } = useOptions();

  return (
    <Grid container spacing={2}>
      <Grid width={"100%"} size={{ xs: 12, md: 4, lg: 3 }}>
        <Field
          name="location"
          label="Location"
          options={[
            {
              label: "All",
              code: "all",
              id: "all",
            },
            ...LOCATIONS,
          ]}
          component={SelectField}
          fullWidth
          disabled={disabled}
        />
      </Grid>

      <Grid width={"100%"} size={{ xs: 12, md: 4, lg: 3 }}>
        <Field
          name="status"
          options={STATUS}
          component={RadioGroupField}
          defaultValue={STATUS?.[0]}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default ClassToolbar;
