import dayjs, { Dayjs } from "dayjs";

export const getEndDate = (
  num: number,
  start_date: Dayjs | null,
  classes: any[]
) => {
  let end_date = start_date;

  if (num < 1 || !start_date || !classes || classes?.length === 0) return;

  const dows: any = {};
  const dow_class = classes?.map((c: any) => {
    const dow = c.day_of_week;
    dows[dow] = dows[dow] ? dows[dow]++ : 1;

    return dow;
  });

  while (0 <= num) {
    const day = dayjs(end_date).day();
    if (num === 0 && dow_class.includes(day)) break;

    if (dow_class.includes(day)) num -= dows[day];
    end_date = dayjs(end_date).add(1, "day");
  }

  return end_date;
};

export const calculateEndDate = (
  total: number,
  start_date: dayjs.Dayjs | null | undefined,
  dows: (number | string)[] | null | undefined
): dayjs.Dayjs | null => {
  // Handle null or undefined inputs
  if (!start_date || !dows || dows.length === 0) {
    return null;
  }

  // Validate total
  if (total <= 0) {
    console.warn("Total must be a positive number");
    return null;
  }

  // Convert dows to numbers and validate
  const validDows = dows
    .map((dow) => (typeof dow === "string" ? parseInt(dow, 10) : dow))
    .filter((dow) => !isNaN(dow) && dow >= 0 && dow <= 6);

  if (validDows.length === 0) {
    console.warn("No valid days of week provided");
    return null;
  }

  // Sort days of week to ensure consistent processing
  const sortedDows = Array.from(new Set(validDows)).sort((a, b) => a - b);

  // Create a fresh copy of the start date to prevent mutation
  let currentDate = dayjs(start_date.toDate());
  let count = 0;

  // Limit iterations to prevent infinite loop
  const MAX_ITERATIONS = 1000;
  let iterations = 0;

  while (count < total && iterations < MAX_ITERATIONS) {
    // Move to next day
    currentDate = currentDate.add(1, "day");
    iterations++;

    // Check if current day is in the specified days of week
    if (sortedDows.includes(currentDate.day())) {
      count++;
    }
  }

  if (iterations >= MAX_ITERATIONS) {
    console.warn("Max iterations reached. Possible infinite loop prevented.");
    return null;
  }

  return currentDate;
};
