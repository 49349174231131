import React, { useState, useEffect, useCallback, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Stack, LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import TableData from "components/TableData";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CREATE } from "constants/ConstantCommon";
import locationApi from "api/locationApi";
import { IRequestLocation } from "types";
import { Header } from "components/Header";
import ActionCell from "features/components/ActionCell";
import Button from "components/Button";

const Location = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // Pagination state
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // External navigation state
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  // Query parameters
  const params: IRequestLocation = { ...paginationModel };

  // Location data query
  const {
    data: locations,
    isLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["locations", params],
    queryFn: () => locationApi.getLocations(params),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: 1,
    placeholderData: (previousData) => previousData,
  });

  // External navigation effect
  useEffect(() => {
    setIsExternalNavigation(true);

    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  // Destructure locations data
  const { rowCount, content: rows } = locations ?? {};

  // Memoized add click handler
  const handleAddClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(CREATE);
    },
    [navigate]
  );

  // Render action cell memoized
  const renderActionCell = useCallback(
    (params: any) => (
      <ActionCell
        row={params.row}
        detailUrl={`${params.row.id}`}
        handleDelete={locationApi.deleteLocation}
        handleCallback={refetch}
      />
    ),
    [refetch]
  );

  // Comprehensive loading state
  const loading = isLoading || (isFetching && isExternalNavigation);

  // Memoized columns with translations
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Location Name"),
        description: t("Location Name"),
        minWidth: 220,
        flex: 1,
      },
      {
        field: "address",
        headerName: t("Address"),
        description: t("Address"),
        minWidth: 160,
        flex: 1,
      },
      {
        field: "contact1",
        headerName: t("Contact Person 1"),
        description: t("Contact Person 1"),
        minWidth: 160,
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "phone1",
        headerName: `${t("Phone Number")} 1`,
        description: `${t("Phone Number")} 1`,
        width: 160,
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "contact2",
        headerName: t("Contact Person 2"),
        description: t("Contact Person 2"),
        minWidth: 160,
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "phone2",
        headerName: `${t("Phone Number")} 2`,
        description: `${t("Phone Number")} 2`,
        minWidth: 160,
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "contact3",
        headerName: t("Contact Person 3"),
        description: t("Contact Person 3"),
        minWidth: 160,
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "phone3",
        headerName: `${t("Phone Number")} 3`,
        description: `${t("Phone Number")} 3`,
        minWidth: 160,
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "",
        width: 120,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        align: "center",
        renderCell: renderActionCell,
      },
    ],
    [t, renderActionCell]
  );

  return (
    <Stack p={2} sx={{ position: "relative" }}>
      {/* Loading Indicator */}
      {isFetching && !isLoading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        />
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Header
          path={[
            {
              text: t("Location List"),
            },
          ]}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          disabled={loading}
          sx={{ textTransform: "none" }}
        >
          {t("Add Location")}
        </Button>
      </Stack>

      <TableData
        rows={rows ?? []}
        columns={columns}
        loading={loading}
        rowCount={rowCount ?? 0}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sx={{ maxHeight: "calc(100vh - 120px)" }}
      />
    </Stack>
  );
};

export default Location;
