import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GridColDef } from "@mui/x-data-grid";
import { Stack, LinearProgress, Avatar, Box } from "@mui/material";
import { CREATE, STUDENT_STATUS } from "constants/ConstantCommon";
import studentApi from "api/studentApi";
import "./styles.scss";
import ActionCell from "features/components/ActionCell";
import { useTranslation } from "react-i18next";
import { Header } from "components/Header";
import TableData from "components/TableData";
import Button from "components/Button";

export default function StudentList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Pagination and filtering state
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // State to track external navigation and loading
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  // Prepare query parameters
  const params = { ...paginationModel };

  // Query for students list
  const {
    data: students,
    isLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["students", params],
    queryFn: () => studentApi.getStudents(params),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: 1,
    placeholderData: (previousData) => previousData,
  });

  // Handle external navigation effect
  useEffect(() => {
    // Set flag when navigating back to this page
    setIsExternalNavigation(true);

    // Reset flag after initial load
    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  // Destructure students data
  const { content: rows, rowCount } = students ?? {};

  // Memoized add click handler
  const handleAddClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(CREATE);
    },
    [navigate]
  );

  // Comprehensive loading state
  const loading = isLoading || (isFetching && isExternalNavigation);

  // Memoized columns definition
  const COLUMNS: GridColDef[] = [
    {
      field: "avatar",
      headerName: t("Avatar"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      renderCell: (params) => (
        <Box display={"flex"} justifyContent={"center"}>
          <Avatar
            alt={`${params.row.name}'s avatar`}
            src={params.value}
            sx={{
              width: 40,
              height: 40,
              mt: 0.7,
            }}
            variant="circular"
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: t("Full Name"),
      description: t("Full Name"),
      minWidth: 160,
      flex: 1,
    },
    {
      field: "year",
      headerName: t("Birth Year"),
      align: "center",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) => {
        const d = new Date(params?.row?.dob);
        return params?.row?.dob ? d.getFullYear() : "";
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => STUDENT_STATUS.get(params?.row?.status),
      flex: 1,
    },
    {
      field: "phone",
      headerName: t("Phone Number"),
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <ActionCell
          row={params.row}
          detailUrl={`${params.row.id}`}
          handleDelete={studentApi.deleteStudent}
          handleCallback={refetch}
        />
      ),
    },
  ];

  return (
    <Stack p={2} sx={{ position: "relative" }}>
      {/* Loading Indicator */}
      {isFetching && !isLoading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        />
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Header
          path={[
            {
              text: t("Student List"),
            },
          ]}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          disabled={loading}
          sx={{ textTransform: "none" }}
        >
          {t("Add New Student")}
        </Button>
      </Stack>

      <TableData
        rows={rows ?? []}
        columns={COLUMNS}
        loading={loading}
        rowCount={rowCount ?? 0}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sx={{ maxHeight: "calc(100vh - 120px)" }}
      />
    </Stack>
  );
}
