import {
  Grid2 as Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FastField, Field, Formik, FormikHelpers, FormikProps } from "formik";
import { CLASS_TABLE_ROW } from "./CLASS_TABLE";
import { CREATE } from "constants/ConstantCommon";
import { useMutation, useQuery } from "@tanstack/react-query";
import classApi from "api/classApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { classFormSchema } from "utils/validation";
import { Header } from "components/Header";
import { CLASS } from "constants/routes";
import ClassToolbar from "../ClassToolbar";
import { renderComponent } from "utils/common";
import teacherApi from "api/teacherApi";
import dayjs from "dayjs";
import { IOption } from "custom-fields/SelectField";
import useOptions from "hooks/useOptions/useOptions";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "components/Button";

// Loading Field component to reuse
const LoadingField = () => (
  <Skeleton variant="rectangular" width="100%" height={37} animation="wave" />
);

// Loading Button component
const LoadingButton = () => (
  <Button
    variant="contained"
    size="small"
    disabled
    startIcon={<CircularProgress size={16} color="inherit" />}
  >
    <Typography variant="body2">Loading...</Typography>
  </Button>
);

const ClassContentFormik = (props: any) => {
  const { handleSubmit, handleReset, isSubmitting, values, isLoading } = props;
  const { location } = values ?? {};

  const { t } = useTranslation();
  const { DOW, CLASS_TYPE, CLASS_STATUS } = useOptions();

  const locationId =
    location?.id && location?.id === "all" ? undefined : location?.id;

  const { data: teacherData, isLoading: teachersLoading } = useQuery({
    queryKey: ["teachers", location?.id],
    queryFn: () => teacherApi.getTeachers({ location_id: locationId }),
    enabled: !!location?.id, // Only fetch when locationId is available
  });

  const TEACHERS =
    teacherData?.content?.map((teacher) => ({
      label: `${teacher.name}`,
      id: teacher?.id,
      code: teacher?.id,
    })) || [];

  const loading = isLoading || teachersLoading;

  const renderOptions = (type: string | undefined) => {
    switch (type) {
      case "dowList":
        return DOW;
      case "typeList":
        return CLASS_TYPE;
      case "statusList":
        return CLASS_STATUS;
      case "teacherList":
        return TEACHERS;
      default:
        return [];
    }
  };

  return (
    <Stack
      p={2}
      spacing={2}
      component={"form"}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <Header
        path={[
          {
            text: t("Class List"),
            route: CLASS,
          },
          {
            text: t("Class"),
          },
        ]}
        disabled={isSubmitting}
      />

      <ClassToolbar disabled={isSubmitting || loading} />

      {loading && (
        <Typography
          variant="body2"
          color="text.secondary"
          textAlign="center"
          sx={{ py: 1 }}
        >
          <CircularProgress size={16} sx={{ mr: 1, verticalAlign: "middle" }} />
          {t("Loading class data...")}
        </Typography>
      )}

      <Grid
        container
        component={Paper}
        elevation={8}
        spacing={{ xs: 1, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        p={2}
      >
        {CLASS_TABLE_ROW.map((item, index) => (
          <Grid width={"100%"} key={index} size={{ xs: 12, md: 4, lg: 3 }}>
            {loading ? (
              <LoadingField />
            ) : (
              <Field
                name={item?.name}
                component={renderComponent(item?.fieldType)}
                options={renderOptions(item?.option)}
                label={item?.label}
                disabled={isSubmitting}
              />
            )}
          </Grid>
        ))}
      </Grid>

      <Stack
        width="100%"
        spacing={2}
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        {loading ? (
          <>
            <LoadingButton />
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              type="reset"
              disabled={isSubmitting}
            >
              {t("Reset")}
            </Button>

            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: "none" }}
              type="submit"
              disabled={isSubmitting}
              startIcon={
                isSubmitting && <CircularProgress size={16} color="inherit" />
              }
            >
              {isSubmitting ? t("Saving...") : t("Save")}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export const ClassDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationRoute = useLocation();

  const { LOCATIONS, DOW, CLASS_STATUS } = useOptions();
  const { status, location: initLocation, dow } = locationRoute.state || {};
  const isEdit = Boolean(id && id !== CREATE);

  const day_of_week = DOW.find((d) => d?.id === dow) || null;

  // State to track external navigation and loading states
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  // Load class data if in edit mode
  const {
    data: classData,
    isLoading: classIsLoading,
    isFetching: classIsFetching,
    error: classError,
  } = useQuery({
    queryKey: ["class", id],
    queryFn: () => id && id !== CREATE && classApi.getClassById(id),
    enabled: isEdit,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  // Load teachers for this class if in edit mode
  const { data: teachers, isLoading: classTeachersLoading } = useQuery({
    queryKey: ["teachers by class", id],
    queryFn: () => teacherApi.getTeachers({ classes: [id as string] }),
    enabled: isEdit && !classIsLoading && !classError,
  });

  // Determine if data is being fetched
  const isLoading =
    classIsLoading ||
    (classIsFetching && isExternalNavigation) ||
    classTeachersLoading;

  // Initial form values
  const initialValues = {
    class_id: isEdit ? classData?.class_id : "",
    name: isEdit ? classData?.name : "",
    day_of_week: isEdit
      ? DOW.find((d) => d?.id === classData?.day_of_week)
      : day_of_week,
    start_at: isEdit ? dayjs(classData?.start_at, "HH:mm:ss") : null,
    end_at: isEdit ? dayjs(classData?.end_at, "HH:mm:ss") : null,
    type: isEdit
      ? { label: classData?.type, code: classData?.type, id: classData?.type }
      : null,
    teachers: isEdit
      ? teachers?.content?.map((teacher) => ({
          id: teacher?.id,
          label: teacher.name,
          code: teacher?.id,
        }))
      : null,
    location: isEdit
      ? LOCATIONS.find((l: IOption) => l?.id === classData?.location)
      : initLocation ?? null,
    status: isEdit
      ? CLASS_STATUS.find((s) => s?.code === classData?.status)
      : status,
  };

  // Create mutation
  const registerNewClass = useMutation({
    mutationFn: classApi.registerNewClass,
    onSuccess: () => {
      toast.success(t("Added new class successfully"));
      navigate(CLASS, {
        state: {
          forceRefresh: true,
        },
      });
    },
    onError: (err: any) => {
      toast.error(t("Error adding class"));
      console.error("Error adding class", err.response?.data);
    },
  });

  // Update mutation
  const updateClass = useMutation({
    mutationFn: classApi.updateClass,
    onSuccess: () => {
      toast.success(t("Updated class successfully"));
      navigate(CLASS, {
        state: {
          forceRefresh: true,
        },
      });
    },
    onError: (err: any) => {
      toast.error(t("Error updating class"));
      console.error("Error updating class", err.response?.data);
    },
  });

  // Form submission handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      const {
        day_of_week,
        location,
        status,
        type,
        teachers,
        start_at,
        end_at,
      } = values ?? {};

      const params = {
        ...values,
        day_of_week: day_of_week?.id,
        location: location?.id === "all" ? undefined : location?.id,
        status: status?.id,
        type: type?.id,
        teachers: teachers?.map((teacher: any) => teacher?.id) || [],
        start_at: start_at ? dayjs(start_at).format("HH:mm:ss") : undefined,
        end_at: end_at ? dayjs(end_at).format("HH:mm:ss") : undefined,
      };

      if (id && id !== CREATE) {
        await updateClass.mutateAsync({ ...params, id });
      } else {
        await registerNewClass.mutateAsync(params);
      }
    } catch (error) {
      console.error(error);
      toast.error(t("An unexpected error occurred"));
    } finally {
      setSubmitting(false);
    }
  };

  // Effect to handle external navigation
  useEffect(() => {
    setIsExternalNavigation(true);
    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [locationRoute.pathname]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={classFormSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps: FormikProps<any>) => (
        <ClassContentFormik
          {...formikProps}
          isLoading={isLoading}
          initLocation={initLocation}
        />
      )}
    </Formik>
  );
};
