import React, { useEffect, useState } from "react";
import { IconButton, Grid2 as Grid, Box, Avatar } from "@mui/material";
import { useFormikContext } from "formik";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import useModalContext from "hooks/useModalContext/useModalContext";
import TableData from "components/TableData";
import AttendanceSearch from "./AttendanceSearch";
import "./styles.scss";
import { Header } from "components/Header";
import { useTranslation } from "react-i18next";
import attendanceApi from "api/attendanceApi";
import classApi from "api/classApi";
import studentApi from "api/studentApi";
import { STUDENT_STATUS } from "constants/ConstantCommon";
import { CellDiemDanh } from "features/components/CellDiemDanh";
import { IRequestAttendance, IRequestStudent, IStudent } from "types";
import { AttendanceFormValues } from ".";
import Button from "components/Button";

// Define the shape of form values

const AttendanceTable: React.FC = () => {
  const formik = useFormikContext<{
    values: AttendanceFormValues;
    handleSubmit: (values: AttendanceFormValues) => void;
  }>();

  const { values, setFieldValue } = formik;

  const {
    setOpen: setShowPopup,
    setTitle: setPopupTitle,
    setContent: setPopupContent,
  } = useModalContext();

  const { t } = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // Safely access values with type checking
  const { date, classes: selectedClass, attendance_student } = values as any;

  const attendanceParams: IRequestAttendance = {
    classes: selectedClass?.id,
    date: dayjs(date).format("YYYY-MM-DD"),
  };

  const { data: attendanceData, refetch: refetchAttendance } = useQuery({
    queryKey: ["attendance", attendanceParams],
    queryFn: () => attendanceApi.getAttendance(attendanceParams),
    enabled: !!selectedClass?.id && !!date,
    placeholderData: (previousData) => previousData,
  });

  const studentParams: IRequestStudent = {
    ...paginationModel,
    classes: [selectedClass?.id],
  };

  const {
    data: studentData,
    refetch: refetchStudent,
    isLoading,
  } = useQuery({
    queryKey: ["students", studentParams],
    queryFn: () => studentApi.getStudents(studentParams),
    enabled: !!selectedClass?.id && !!date,
    placeholderData: (previousData) => previousData,
  });

  const rowCount = studentData?.rowCount ?? 0;

  const { data: usedUnit } = useQuery({
    queryKey: ["usedUnitClass", selectedClass?.id],
    queryFn: () => classApi.getUsedUnit(selectedClass?.id),
    enabled: !!selectedClass?.id,
  });

  const attendances = attendanceData?.content?.[0]?.attendance_student ?? {};

  //   attendance && setFieldValue("attendance_student", attendance);

  //   const attended_student_ids = Object.keys(attendance_student);

  const students = studentData?.content ?? [];

  const COLUMNS: GridColDef[] = [
    {
      field: "avatar",
      headerName: "Ảnh đại diện",
      description: "Ảnh đại diện",
      align: "center",
      headerAlign: "center",
      sortable: false,
      maxWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Box display={"flex"} justifyContent={"center"}>
          <Avatar
            alt={`${params.row.name}'s avatar`}
            src={params.value}
            sx={{
              width: 40,
              height: 40,
              mt: 0.7,
            }}
            variant="circular"
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Họ tên",
      description: "Họ tên",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Trạng thái học",
      description: "Trạng thái học",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params: GridRenderCellParams) =>
        STUDENT_STATUS.get(params?.row?.status),
    },
    {
      field: "year",
      headerName: "Năm sinh",
      description: "Năm sinh",
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const d = new Date(params.row.dob);
        return params?.row?.dob ? d.getFullYear() : "";
      },
    },
    {
      field: "phone",
      headerName: "Số điện thoại",
      description: "Số điện thoại",
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unit_num",
      headerName: "Số buổi",
      description: "Số buổi học",
      width: 75,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unit_used",
      headerName: "Đã học",
      description: "Số buổi đã học",
      width: 70,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => (
        <>{usedUnit && usedUnit[params.row.id]}</>
      ),
    },
    {
      field: "unit_rest",
      headerName: "Còn lại",
      description: "Số buổi học còn lại",
      width: 70,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        const unitRest = usedUnit
          ? params.row.unit_num - usedUnit[params.row.id]
          : 0;
        return <>{unitRest}</>;
      },
    },
    {
      field: "diemdanh",
      headerName: "Điểm danh",
      description: "Điểm danh",
      width: 150,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => (
        <CellDiemDanh
          params={params}
          attendances={attendances}
          setFieldValue={setFieldValue}
        />
      ),
    },
    {
      field: "note",
      headerName: "Ghi chú",
      description: "Ghi chú",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            color="default"
            aria-label="Ghi Chú"
            component="label"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              handleNoteClick(e, params.row)
            }
          >
            <EventNoteIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleNoteClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setShowPopup(true);
    setPopupTitle("Ghi Chú");
    setPopupContent(row.id);
  };

  useEffect(() => {
    if (attendanceData?.content?.[0]) {
      setFieldValue("id", attendanceData?.content?.[0]?.id);
    }
  }, [attendanceData?.content?.[0]]);

  return (
    <Box p={2} component="form" onSubmit={formik.handleSubmit}>
      <Header
        path={[
          {
            text: t("Attendance List"),
          },
        ]}
      />

      <Grid container spacing={2} mt={2}>
        <Grid size={{ xs: 12, md: "auto" }} width={{ xs: "100%", md: "352px" }}>
          <AttendanceSearch />
        </Grid>

        <Grid
          size={{ xs: 12, md: "auto" }}
          width={{ xs: "100%", md: "calc(100% - 370px)" }}
        >
          <TableData
            rows={students ?? []}
            columns={COLUMNS}
            loading={isLoading}
            rowCount={rowCount}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            sx={{ maxHeight: "calc(100vh - 120px)" }}
          />
        </Grid>
      </Grid>

      <Box
        width="100%"
        gap={2}
        mt={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ textTransform: "none" }}
          type="reset"
          // disabled={isSubmitting || isSubmittingOrFetching}
        >
          {t("Reset")}
        </Button>

        <Button
          variant="contained"
          size="small"
          sx={{ textTransform: "none" }}
          type="submit"
          // disabled={isSubmitting || isSubmittingOrFetching}
        >
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default AttendanceTable;
