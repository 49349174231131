import dayjs from "dayjs";
import { Fee } from "types";

type ConversionRule<T, U> = {
  [K in keyof U]?: (item: T) => U[K];
};

export const convertArrayToType = <T, U>(
  data: T[] | undefined,
  conversionRules: ConversionRule<T, U>
): U[] => {
  if (!data) return [];

  return data.map((item) => {
    const convertedItem: Partial<U> = {};

    // Apply conversion rules
    Object.keys(conversionRules).forEach((key) => {
      const conversionFn = conversionRules[key as keyof U];
      if (conversionFn) {
        convertedItem[key as keyof U] = conversionFn(item);
      }
    });

    return convertedItem as U;
  });
};

// Helper function to convert dates from DD/MM/YYYY to YYYY-MM-DD
export const convertToISODate = (
  dateString?: string | null
): string | undefined =>
  dateString ? dayjs(dateString).format("YYYY-MM-DD") : undefined;

// Example usage for Fee conversion
export const convertFeesToFeeData = (fees?: any[]) =>
  convertArrayToType<any, Fee>(fees, {
    id: (f) => f.id,
    classes: (f) => f.classes ?? undefined,
    amount: (f) => f.amount,
    discount: (f) => f.discount,
    gift: (f) => f.gift?.id ?? undefined,
    pay_date: (f) => convertToISODate(f.pay_date),
    start_date: (f) => convertToISODate(f.start_date),
    end_date: (f) => convertToISODate(f.end_date),
    unit_num: (f) => f.unit_num,
    unit_used: (f) => f.unit_used,
    status: (f) => f.status,
  });
