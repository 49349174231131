import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import common from "./common";
import account from "./account";

// Redux persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["account"], // only account will be persisted
  // blacklist: [], // use this to exclude slices from being persisted
};

const rootReducer = combineReducers({
  common,
  account,
});

const persistedReducer = persistReducer<any>(persistConfig, rootReducer);

export default persistedReducer;
