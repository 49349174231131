import AutocompleteField from "custom-fields/AutocompleteField";
import DatePickerField from "custom-fields/DatePickerField";
import ImageUploadField from "custom-fields/ImageUploadField";
import InputField from "custom-fields/InputField";
import MultiAutocompleteField from "custom-fields/MultiAutocompleteField";
import SelectField from "custom-fields/SelectField";
import TimePickerField from "custom-fields/TimePickerField";

export const renderComponent = (type: string) => {
  switch (type) {
    case "InputField":
      return InputField;
    case "TimePickerField":
      return TimePickerField;
    case "DatePickerField":
      return DatePickerField;
    case "SelectField":
      return SelectField;
    case "AutocompleteField":
      return AutocompleteField;
    case "MultiAutocompleteField":
      return MultiAutocompleteField;
    case "ImageUploadField":
      return ImageUploadField;

    default:
      return type;
  }
};

export const removeNullUndefined = (obj: any) => {
  return Object.entries(obj).reduce<Record<string, any>>(
    (acc, [key, value]) => {
      // Handle arrays
      if (Array.isArray(value)) {
        const filteredArray = value.filter(
          (item) =>
            item !== null &&
            item !== undefined &&
            (typeof item !== "string" || item.trim() !== "")
        );

        // Always set the key to an array, even if empty
        acc[key] = filteredArray;
      }
      // Handle non-array values
      else if (value !== null && value !== undefined) {
        // Check if value is string and trim it
        acc[key] = typeof value === "string" ? value.trim() : value;
      }

      return acc;
    },
    {}
  );
};

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); // use high-precision timer if available
  }

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

/**
 * Recursively removes empty strings, null, undefined, and NaN values from an object
 * @param obj The input object to clean
 * @returns A new object with falsy values removed
 */
export function cleanObject<T extends Record<string, any>>(
  obj: T
): { [K in keyof T]?: T[K] } {
  // If input is not an object or is null, return it as is
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(cleanObject) as any;
  }

  // For objects, create a new object with cleaned properties
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => {
        // Remove empty string
        if (value === "") return false;

        // Remove null
        if (value === null) return false;

        // Remove undefined
        if (value === undefined) return false;

        // Remove NaN
        if (typeof value === "number" && Number.isNaN(value)) return false;

        return true;
      })
      .map(([key, value]) => {
        // Recursively clean nested objects and arrays
        return [key, cleanObject(value)];
      })
  ) as { [K in keyof T]?: T[K] };
}
