export const MENU_MANAGER = new Map([
  ["student", "Học Sinh"],
  ["attendance", "Điểm Danh"],
  ["teacher", "Huấn Luyện Viên"],
  ["class", "Lớp Học"],
  ["catalog", "Danh Mục"],
  ["statistic", "Thống Kê"],
]);

export const SETTINGS = new Map([
  ["profile", "Hồ sơ cá nhân"],
  ["account", "Tài khoản"],
  ["dashboard", "Dashboard"],
  ["logout", "Đăng xuất"],
]);

export const DAY_OF_WEEK = new Map([
  [0, "Chủ Nhật"],
  [1, "Thứ 2"],
  [2, "Thứ 3"],
  [3, "Thứ 4"],
  [4, "Thứ 5"],
  [5, "Thứ 6"],
  [6, "Thứ 7"],
]);

export const STUDENT_STATUS = new Map([
  ["DAHOC", "Đã nhập học"],
  ["HENHOC", "Hẹn học thử"],
  ["HOCTHU", "Học thử"],
  ["HOI", "Hỏi thông tin"],
  ["NGHIHOC", "Đã nghỉ học"],
  ["BAOLUU", "Bảo lưu"],
]);

export enum PERMISSION_LIST {
  Dashboard = "Dashboard",
  PatientMonitor = "Patient Monitor",
  PatientList = "Member Management",
  Inbox = "Inbox Message",
  Appointment = "Appointment",
  User = "User Management",
  CounselorList = "Counselor Management",
  Setting = "Setting",
  Profile = "Profile",
  DoctorScheduling = "Counselor Scheduling",
  Scheduling = "Scheduling",
  ServiceSetting = "Counseling Type",
  OrganizationSetting = "Organization Setting",
  WaitingRoom = "Waiting Room",
}

export enum ROLE_LIST {
  COORDINATOR = "ClinicStaff",
  COUNSELOR = "Doctor",
  PATIENT = "Patient",
  SUPER_ADMIN = "SystemAdmin",
  CENTER_DIRECTOR = "ClinicAdmin",
  MEMBER = "Patient",
}

export const POPUP_SUBMIT = "submit";
export const POPUP_NORMAL = "normal";
export const POPUP_NO_ACTION = "noAction";
export const POPUP_INFORM = "inform";

export const CREATE = "create";
export const UPDATE = "update";
export const DELETE = "delete";
export const READ = "read";
