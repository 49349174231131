import { useState, useEffect } from "react";
import {
  Box,
  Grid2 as Grid,
  Paper,
  Skeleton,
  LinearProgress,
} from "@mui/material";
import { Field, Formik, FormikHelpers } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { TEACHER_TABLE } from "./TEACHER_TABLE";
import teacherApi from "api/teacherApi";
import { TeacherFormSchema } from "utils/validation";
import { CREATE } from "constants/ConstantCommon";
import { Header } from "components/Header";
import { useTranslation } from "react-i18next";
import { TEACHER } from "constants/routes";
import { toast } from "react-toastify";
import useOptions from "hooks/useOptions/useOptions";
import { renderComponent } from "utils/common";
import { IOption } from "custom-fields/SelectField";
import Button from "components/Button";

export interface ITeacher {
  id?: string | number;
  teacher_id?: string;
  name?: string; // Changed to optional
  role?: string; // You might want to make other properties optional too
  start_date: Date | null;
  salary?: number;
  allowance?: number;
  phone?: string;
  seniority?: number;
  locations?: number[] | string[];
}

const TeacherDetail = (): JSX.Element => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { LOCATIONS } = useOptions();

  // State to track external navigation
  const [isExternalNavigation, setIsExternalNavigation] = useState(false);

  const {
    data: teacher,
    isLoading: teacherIsLoading,
    isFetching: teacherIsFetching,
  } = useQuery({
    queryKey: ["teacher", id],
    queryFn: () => id && id !== CREATE && teacherApi.getTeacherById(id),
    enabled: !!id && id !== CREATE,
    // Ensure refetching when returning to the page
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  // Add effect to handle external navigation
  useEffect(() => {
    // Set flag when navigating back to this page
    setIsExternalNavigation(true);

    // Reset flag after initial load
    const timer = setTimeout(() => {
      setIsExternalNavigation(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  const initialValues = {
    name: teacher?.name ?? "",
    role: teacher?.role ?? "",
    start_date: teacher?.start_date ?? null,
    salary: teacher?.salary ?? 0,
    allowance: teacher?.allowance ?? 0,
    phone: teacher?.phone ?? "",
    seniority: teacher?.seniority ?? 0,
    locations:
      LOCATIONS?.filter((item) => teacher?.locations?.includes(item.id)) ?? [],
  } as ITeacher;

  const registerNewTeacher = useMutation({
    mutationFn: teacherApi.createTeacher,
    onSuccess: () => {
      toast.success(t("Create teacher success"));
      navigate(TEACHER);
    },
    onError: () => {
      toast.error(t("Create teacher fail"));
    },
  });

  const updateTeacher = useMutation({
    mutationFn: teacherApi.updateTeacher,
    onSuccess: () => {
      toast.success(t("Update teacher success"));
      navigate(TEACHER);
    },
    onError: () => {
      toast.error(t("Update teacher fail"));
    },
  });

  const handleSubmit = async (
    values: ITeacher,
    { setSubmitting }: FormikHelpers<ITeacher>
  ) => {
    const { locations } = values ?? {};
    const locationIds =
      locations
        ?.map((location: IOption) => location.id)
        ?.filter((item) => item !== null && item !== undefined) ?? [];

    const params = {
      ...values,
      locations: [...locationIds],
    };

    try {
      if (id && id !== CREATE) {
        await updateTeacher.mutateAsync({ ...params, id });
      } else {
        await registerNewTeacher.mutateAsync(params);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const renderOptions = (type: string | undefined) => {
    switch (type) {
      case "locationList":
        return LOCATIONS;
      default:
        break;
    }
  };

  // Determine if teacher data is being fetched
  const isLoadingTeacher =
    teacherIsLoading || (teacherIsFetching && isExternalNavigation);

  // Combine submission states
  const isSubmittingOrFetching =
    registerNewTeacher.isPending ||
    updateTeacher.isPending ||
    teacherIsFetching;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TeacherFormSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(props) => {
        const { handleSubmit, handleReset, isSubmitting } = props;
        return (
          <Box
            p={2}
            component="form"
            onSubmit={handleSubmit}
            onReset={handleReset}
            sx={{ position: "relative" }}
          >
            {/* Fetching/Submitting Progress Indicator */}
            {isSubmittingOrFetching && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                }}
              />
            )}

            <Header
              path={[
                {
                  text: t("Teacher List"),
                  route: TEACHER,
                },
                {
                  text: t("Teacher"),
                },
              ]}
              disabled={isSubmitting || isSubmittingOrFetching}
            />

            <Grid
              container
              component={Paper}
              elevation={8}
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              mt={2}
              p={2}
              sx={{
                opacity: isSubmittingOrFetching ? 0.7 : 1,
                pointerEvents: isSubmittingOrFetching ? "none" : "auto",
              }}
            >
              {TEACHER_TABLE.map((item, index) => (
                <Grid key={index} size={{ xs: 12, md: 6, lg: 4 }}>
                  {isLoadingTeacher ? (
                    <Skeleton variant="rectangular" width="100%" height={37} />
                  ) : (
                    <Field
                      {...item}
                      component={renderComponent(item?.fieldType)}
                      options={renderOptions(item?.options)}
                      disabled={isSubmitting || isSubmittingOrFetching}
                    />
                  )}
                </Grid>
              ))}
            </Grid>

            {!isLoadingTeacher && (
              <Box
                width="100%"
                gap={2}
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  type="reset"
                  disabled={isSubmitting || isSubmittingOrFetching}
                >
                  {t("Reset")}
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: "none" }}
                  type="submit"
                  disabled={isSubmitting || isSubmittingOrFetching}
                >
                  {t("Save")}
                </Button>
              </Box>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};

export default TeacherDetail;
