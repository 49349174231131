import { Stack, useMediaQuery, useTheme, Box } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import "./styles.modules.scss";
import TopBar from "components/global/Topbar";
import SideBar from "components/global/Sidebar";

const MainLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Stack
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <TopBar />

      <Stack
        direction="row"
        mt={"54px"}
        sx={{
          width: "100%",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {/* Only show sidebar on non-mobile screens */}
        {!isMobile && (
          <SideBar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        )}

        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
};

export default MainLayout;
