import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { FieldArray } from "formik";
import TableData from "components/TableData";
import { useCallback } from "react";
import Button from "components/Button";
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useModalContext from "hooks/useModalContext/useModalContext";
import { Fee } from "types";
import { IOption } from "custom-fields/SelectField";
import FeeDetail from "./FeeDetail";
import { POPUP_NO_ACTION } from "constants/ConstantCommon";
import dayjs from "dayjs";

export interface IFeeFormik extends Omit<Fee, "status"> {
  class?: IOption | null;
  status?: IOption | null;
  location?: IOption | null;
}

const Fees = (props: any) => {
  const { fees, loading, paginationModel, setPaginationModel, sx } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const showAddNewTuition = !fees?.some(
    (fee: Fee) => fee.status === "PENDING" || fee.status === "PAID"
  );

  const {
    setOpen: setShowPopup,
    setTitle: setPopupTitle,
    setContent: setPopupContent,
    setType: setPopupType,
    setMaxWidth,
  } = useModalContext();

  const renderActionCell = (
    params: any,
    remove: (index: number) => void,
    replace: (index: number, fee: IFeeFormik) => void
  ) => {
    const handleEdit = () => {
      setShowPopup(true);
      setPopupType(POPUP_NO_ACTION);
      setPopupTitle(t("Add New Tuition"));
      setMaxWidth?.("md");
      setPopupContent(
        <FeeDetail fees={fees} fee={params?.row} replace={replace} />
      );
    };

    const handleDelete = () => {
      remove(params?.rowIndex);
      // console.log(params?.row?.id);
    };

    return (
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={1}
      >
        <IconButton color="primary" onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };

  const renderStatus = (params: any) => {
    const statusColors: Record<string, string> = {
      PAID: "success",
      PENDING: "warning",
      OVERDUE: "error",
    };

    const color = statusColors[params.row.status] || "default";

    return (
      <Typography mt={1.5} fontWeight={700} color={color}>
        {params.row.status_name || "-"}
      </Typography>
    );
  };

  // Option 1: Use all dependencies in useMemo
  const COLUMN: GridColDef[] = [
    {
      field: "status",
      align: "center",
      headerName: t("Status"),
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      renderCell: renderStatus,
    },
    {
      field: "classes",
      headerName: t("Class"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => params?.row?.class_name || t("Not Assigned"),
    },
    // {
    //   field: "location",
    //   headerName: t("Location"),
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params: any) =>
    //     params?.row?.location_name || t("Not Assigned"),
    // },
    {
      field: "amount",
      headerName: t("Amount"),
      align: "center",
      type: "number",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) =>
        params?.value
          ? new Intl.NumberFormat("vi-VN", {
              style: "currency",
              currency: "VND",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(Number(params?.value))
          : "-",
    },
    {
      field: "start_date",
      headerName: t("Start Date"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) =>
        params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "-",
    },
    {
      field: "end_date",
      headerName: t("End Date"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) =>
        params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "-",
    },
    {
      field: "unit_num",
      headerName: t("Total Units"),
      headerAlign: "center",
      align: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "unit_used",
      headerName: t("Used Units"),
      headerAlign: "center",
      align: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "discount",
      headerName: t("Discount"),
      headerAlign: "center",
      align: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) =>
        params?.value ? `${Number(params?.value).toLocaleString()} %` : "-",
    },
    {
      field: "gift",
      headerName: t("Gift"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => params?.row?.gift_name || "-",
    },
    {
      field: "pay_date",
      headerName: t("Pay Date"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) =>
        params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "-",
    },
  ];

  const renderStudentFee = useCallback(
    (formikProps: any) => {
      const { remove, replace } = formikProps;

      const handleAddFee = () => {
        setShowPopup(true);
        setPopupType(POPUP_NO_ACTION);
        setMaxWidth?.("md");
        setPopupTitle(t("Add New Tuition"));
        setPopupContent(<FeeDetail {...formikProps} />);
      };

      return (
        <Box>
          <TableData
            rows={fees ?? []}
            columns={[
              ...COLUMN,
              {
                field: "actions",
                headerName: "",
                flex: 1,
                align: "center",
                minWidth: 100,
                renderCell: (params: any) =>
                  renderActionCell(params, remove, replace),
              },
            ]}
            rowCount={fees?.length}
            loading={loading}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
          {showAddNewTuition && (
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Button
                variant="text"
                style={{ textTransform: "none" }}
                onClick={handleAddFee}
              >
                + {t("Add New Tuition")}
              </Button>
            </Box>
          )}
        </Box>
      );
    },
    [fees, paginationModel]
  );

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          width: "100%",
          backgroundColor: theme.palette.action.hover,
          "& .MuiAccordionSummary-content": {
            margin: theme.spacing(1, 0),
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <CreditCardIcon color="primary" />
          <Typography variant="h6" fontWeight="bold">
            {t("Tuition")}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          p: 2,
          maxHeight: 500,
          overflowY: "auto",
          width: "100%",
          ...sx,
        }}
      >
        <FieldArray name="fees">{renderStudentFee}</FieldArray>
      </AccordionDetails>
    </Accordion>
  );
};

export default Fees;
