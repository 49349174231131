// ImageUploadField.tsx
import React, { useState, useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import FileUploadButton from "components/FileUploadButton";

const ImageUploadField = (props: any) => {
  const {
    field: { name },
    form: { setFieldValue },
    styles,
    initialValues,
    disabled,
  } = props;

  const [previewUrl, setPreviewUrl] = useState<string>(initialValues);
  const [file, setFile] = useState<File | null>();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const base64String = reader.result.toString();
        // Update both preview and form value
        setPreviewUrl(base64String);
        setFile(file);
      }
    };
    file && reader.readAsDataURL(file);
  };

  // Update preview when initial value changes
  useEffect(() => {
    if (initialValues) {
      setPreviewUrl(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (file) {
      setFieldValue(name, file);
    }
  }, [file]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", ...styles }}>
      <Avatar alt="avatar" src={previewUrl} sx={{ width: 100, height: 100 }} />
      <FileUploadButton disabled={disabled} handleChange={handleImageUpload} />
    </Box>
  );
};

export default React.memo(ImageUploadField);
