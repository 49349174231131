import { Dispatch, SetStateAction, memo } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { IOption } from "custom-fields/SelectField";
import { useTranslation } from "react-i18next";

interface IProps {
  field: any;
  form: any;
  label: string;
  options: IOption[];
  disabled?: boolean;
  handleCallback?: Dispatch<SetStateAction<any>>;
  defaultValue?: IOption;
}

function RadioGroupField(props: IProps) {
  const { field, form, options, defaultValue, disabled } = props;
  const { name, value } = field;
  const { setFieldValue } = form;

  const { t } = useTranslation();

  // Current selected value (controlled component)
  const currentValue = value?.id || defaultValue?.id || "";

  const handleToggle = (event: any) => {
    const id = event.target.value;
    const selectedOption = options?.find((option) => option.id === id);
    setFieldValue(name, selectedOption);
  };

  return (
    <RadioGroup
      row
      name={name}
      value={currentValue} // Use controlled value
      onChange={handleToggle}
    >
      {options?.map((radioOption: IOption, index: number) => (
        <FormControlLabel
          key={index}
          value={radioOption?.id}
          control={<Radio size="small" />}
          label={t(radioOption?.label as string, {
            defaultValue: radioOption.label as string,
          })}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  );
}

export default memo(RadioGroupField);
