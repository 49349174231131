import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import { makeStyles } from "@mui/styles";

interface IProps {
  params: any;
  attendances: any;
  setFieldValue?: any;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  disabled: {
    "&&": {
      backgroundColor: "transparent",
      color: "rgba(0, 0, 0, 0.2)",
    },
  },
}));

export const CellDiemDanh = (props: IProps) => {
  const { params, attendances, setFieldValue, disabled } = props;

  const [value, setValue] = useState<string | null>();

  const styles = useStyles();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    status: string | null
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(status);
    if (params?.row?.id) attendances[params?.row?.id] = status;
    setFieldValue("attendance_student", attendances);
  };

  useEffect(() => {
    if (
      !attendances ||
      !params?.id ||
      !Object.keys(attendances).includes(`${params?.id}`)
    )
      return;

    setValue(attendances[params?.id]);
  }, [attendances, params?.id]);

  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      // value={diemdanh[params.id]}
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="text"
      disabled={disabled}
      sx={{ mt: 1 }}
    >
      <ToggleButton value="Đi Học">
        <Tooltip title="Đi Học">
          <CheckIcon />
        </Tooltip>
      </ToggleButton>

      <ToggleButton value="Nghỉ không phép">
        <Tooltip title="Nghỉ không phép">
          <PersonOffIcon />
        </Tooltip>
      </ToggleButton>

      <ToggleButton value="Nghỉ có phép">
        <Tooltip title="Nghỉ có phép">
          <PersonAddDisabledIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
