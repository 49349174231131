import {
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  AutocompleteProps,
} from "@mui/material";
import { IOption } from "custom-fields/SelectField";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  field: {
    name: string;
    value: IOption | null;
    onBlur: () => void;
  };
  form: {
    errors: Record<string, string>;
    touched: Record<string, boolean>;
    setFieldValue: (field: string, value: any) => void;
  };
  label: string;
  options: IOption[];
  size?: "small" | "medium";
  sx?: object;
  type?: string;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  handleStartAdornmentClick?: () => void;
  handleEndAdornmentClick?: () => void;
  handleCallback?: () => void;
}

function AutocompleteField({
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue },
  label,
  options = [],
  size = "small",
  sx,
  disabled = false,
  startIcon,
  endIcon,
  fullWidth = true,
  handleStartAdornmentClick,
  handleEndAdornmentClick,
  handleCallback,
}: IProps) {
  const showError = errors[name] && touched[name];

  const { t } = useTranslation();

  const handleChange: AutocompleteProps<
    IOption,
    false,
    false,
    false
  >["onChange"] = (_, newValue) => {
    setFieldValue(name, newValue);
    handleCallback?.();
  };

  return (
    <Autocomplete<IOption, false, false, false>
      value={value}
      onBlur={onBlur}
      isOptionEqualToValue={(option: IOption, val: IOption) =>
        option?.id === val?.id
      }
      getOptionLabel={(option: IOption) => (!option ? "" : option.label ?? "")}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          error={!!showError}
          helperText={showError && errors[name]}
          InputProps={{
            ...params.InputProps,
            startAdornment: startIcon && (
              <InputAdornment position="start">
                <IconButton
                  color={showError ? "error" : "default"}
                  disabled={disabled || !handleStartAdornmentClick}
                  onClick={handleStartAdornmentClick}
                  edge="start"
                  sx={{ width: 30, height: 30, m: 0 }}
                >
                  {startIcon}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {endIcon && (
                  <InputAdornment position="end">
                    <IconButton
                      color={showError ? "error" : "default"}
                      disabled={disabled || !handleEndAdornmentClick}
                      onClick={handleEndAdornmentClick}
                      edge="end"
                      sx={{ width: 30, height: 30, m: 0 }}
                    >
                      {endIcon}
                    </IconButton>
                  </InputAdornment>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option: IOption) => {
        const { key, ...otherProps } = props;
        return (
          <li key={key} {...otherProps}>
            {t(option?.label as string, {
              defaultValue: option?.label as string,
            })}
          </li>
        );
      }}
      onChange={handleChange}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      sx={sx}
    />
  );
}

export default AutocompleteField;
