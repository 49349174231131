/* auth */
export const SIGNUP = "/auth/sign-up";
export const SIGNIN = "/auth/sign-in";
export const FORGOT_PASSWORD = "/auth/email-reset";
export const RESET_PASSWORD = "/auth/password-reset";

/* portal */
export const ATTENDANCE = "/portal/attendance";
export const ATTENDANCE_DETAIL = "/portal/attendance/:id";
export const USER_PROFILE = "/portal/user-profile";
export const SETTINGS = "/portal/settings";
export const LOCATION = "/portal/location";
export const LOCATION_DETAIL = "/portal/location/:id";
export const CLASS = "/portal/class";
export const CLASS_DETAIL = "/portal/class/:id";
export const STUDENT = "/portal/student";
export const STUDENT_DETAIL = "/portal/student/:id";
export const TEACHER = "/portal/teacher";
export const TEACHER_DETAIL = "/portal/teacher/:id";
export const STATISTIC = "/portal/statistic";
