import { useState } from "react";
import { DateCalendar, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/vi";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme, Typography, Box } from "@mui/material";
import { viLocaleText } from "custom-fields/DatePickerField";

interface IProps {
  field: any;
  form: any;
  disabled?: any;
  type?: string;
  size?: "small" | "medium" | undefined;
  label?: string;
  fullWidth?: boolean;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  handleCallback?: () => void;
}

export default function DateCalendarField(props: IProps) {
  const {
    field,
    form,
    label,
    size = "small",
    fullWidth = true,
    maxDate,
    minDate,
    handleCallback,
  } = props;
  const { name } = field;
  const { setFieldValue, values, errors, touched } = form;
  const showError = errors[name] && touched[name];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { t, i18n } = useTranslation();

  const [value, setValue] = useState<Dayjs | null>(
    values[name]
      ? dayjs(values[name]).locale(i18n.language === "vi" ? "vi" : "en")
      : null
  );

  const handleChange = (newValue: any) => {
    const localizedValue = dayjs(newValue).locale(
      i18n.language === "vi" ? "vi" : "en"
    );
    setValue(localizedValue);
    setFieldValue(name, localizedValue.format("YYYY-MM-DD"));
    handleCallback?.();
  };

  if (isSmallScreen)
    return (
      <MobileDatePicker
        label={t(label as string, { defaultValue: label as string })}
        value={value}
        onChange={handleChange}
        localeText={i18n.language === "vi" ? viLocaleText : {}}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            error: showError,
            helperText: showError && t(errors[name]),
            size: size,
            fullWidth: fullWidth,
          },
        }}
      />
    );

  return (
    <Box>
      <DateCalendar
        value={value}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        sx={{
          "& .MuiPickersCalendarHeader-root": {
            textTransform: "capitalize",
          },
          "& .MuiDayCalendar-weekDayLabel": {
            textTransform: "uppercase",
            ...(i18n.language === "VI" && {
              "&:nth-child(1)::after": { content: '"2"', display: "block" },
              "&:nth-child(2)::after": { content: '"3"', display: "block" },
              "&:nth-child(3)::after": { content: '"4"', display: "block" },
              "&:nth-child(4)::after": { content: '"5"', display: "block" },
              "&:nth-child(5)::after": { content: '"6"', display: "block" },
              "&:nth-child(6)::after": { content: '"7"', display: "block" },
              "&:nth-child(7)::after": { content: '"N"', display: "block" },
              "& > span": { display: "none" },
            }),
          },
          width: fullWidth ? "100%" : "auto",
        }}
      />
      {showError && (
        <Typography
          color="error"
          variant="caption"
          component="div"
          sx={{
            textAlign: "center",
            mt: 1,
            px: 2,
          }}
        >
          {t(errors[name])}
        </Typography>
      )}
    </Box>
  );
}
