import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface IProps {
  field: { name: string; value: any };
  form: {
    setFieldValue: (field: string, value: any) => void;
    errors: Record<string, any>;
    touched: Record<string, boolean>;
  };
  label?: string;
  options?: IOption[] | null;
  defaultValue?: any;
  fullWidth?: boolean;
  width?: string | number;
  required?: boolean;
  placeholder?: string;
  labelStyle?: Record<string, any>;
  size?: "small" | "medium";
  handleCallback?: Dispatch<SetStateAction<any>>;
  minWidth?: number;
  selectProps?: Record<string, any>;
  disabled?: boolean;
  formControlStyle?: Record<string, any>;
  errorStyle?: Record<string, any>;
  maxWidth?: string | number;
  titleStyles?: Record<string, any>;
  isValueOption?: boolean;
}

export interface IOption {
  id?: string;
  code?: string | boolean | number;
  label?: string | null;
  checked?: boolean;
  translatedLabel?: string | null;
  value?: string | number;
  dow?: number;
}

function SelectField({
  field: { name, value },
  form: { setFieldValue, errors, touched },
  label,
  options = [],
  required = false,
  formControlStyle = {},
  size = "small",
  minWidth,
  selectProps = {},
  handleCallback,
  disabled = false,
  fullWidth = true,
  placeholder,
  isValueOption = true,
}: IProps) {
  const { t } = useTranslation();

  // Check if value exists in options to prevent out-of-range error
  const isValidOption = () => {
    if (!value) return false;

    const valueToCheck = isValueOption ? value.code : value;
    return options?.some(
      (option) =>
        (typeof option === "string" ? option : option.code) === valueToCheck
    );
  };

  // Initialize selectValue - either use valid value or empty string
  const [selectValue, setSelectValue] = useState<string | number>(() => {
    if (isValueOption && value) {
      // Convert boolean values to strings to avoid type issues
      const valCode = value.code !== undefined ? value.code : "";
      return isValidOption()
        ? typeof valCode === "boolean"
          ? String(valCode)
          : valCode
        : "";
    }
    // Convert boolean values to strings
    return isValidOption()
      ? typeof value === "boolean"
        ? String(value)
        : value ?? ""
      : "";
  });

  const showError = touched[name] && errors[name];

  const handleChange = (event: SelectChangeEvent<any>) => {
    const selectedValue = event.target.value;
    setSelectValue(selectedValue);

    if (!isValueOption) {
      setFieldValue(name, selectedValue);
      handleCallback?.(selectedValue);
      return;
    }

    const selectedOption =
      options?.find((option) => {
        const optionCode = option?.code;
        const eventValue = selectedValue;
        return String(optionCode) === String(eventValue);
      }) || null;

    setFieldValue(name, selectedOption);
    handleCallback?.(selectedOption);
  };

  useEffect(() => {
    if (isValidOption()) {
      const newValue = isValueOption && value ? value.code : value;
      // Convert boolean values to strings to avoid type errors
      setSelectValue(
        typeof newValue === "boolean" ? String(newValue) : newValue ?? ""
      );
    } else {
      setSelectValue("");
    }
  }, [value, isValueOption, options]);

  return (
    <FormControl
      sx={formControlStyle}
      size={size}
      fullWidth={fullWidth}
      required={required}
      error={Boolean(showError)}
    >
      {label && (
        <InputLabel htmlFor={`${name}-label`} id={`${name}-label`}>
          {t(label as string, { defaultValue: label as string })}
        </InputLabel>
      )}
      <Select
        labelId={`${name}-label`}
        id={name}
        value={selectValue}
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}
        fullWidth={fullWidth}
        disabled={disabled}
        label={label}
        required={required}
        sx={{ minWidth, width: "100%", ...selectProps }}
        error={Boolean(showError)}
      >
        {placeholder && (
          <MenuItem value="">
            <span style={{ color: "#6F727A", opacity: 0.7 }}>
              {t(placeholder as string, {
                defaultValue: placeholder as string,
              })}
            </span>
          </MenuItem>
        )}
        {Array.isArray(options) &&
          options.map((option, index) => {
            // Generate a safe key that's always a valid React key type
            const safeKey =
              typeof option?.code === "boolean"
                ? `bool-${String(option.code)}-${index}` // Convert boolean to string for key
                : option?.code !== undefined
                ? String(option.code)
                : `index-${index}`;

            // Generate a safe value that MenuItem can use
            const safeValue =
              typeof option === "string"
                ? option
                : typeof option.code === "boolean"
                ? String(option.code)
                : option.code;

            return (
              <MenuItem key={safeKey} value={safeValue}>
                {typeof option === "string"
                  ? t(option)
                  : t(option?.label as string, {
                      defaultValue: option?.label as string,
                    })}
              </MenuItem>
            );
          })}
      </Select>
      {showError && (
        <FormHelperText error>
          {t(errors[name] as string, { defaultValue: errors[name] as string })}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectField;
