import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState, useMemo, ReactNode } from "react";
import { IOption } from "custom-fields/SelectField";
import { useTranslation } from "react-i18next";

interface IProps {
  field: any;
  form: any;
  label: any;
  options: readonly IOption[];
  size?: "small" | "medium" | undefined;
  width?: number;
  minWidth?: string;
  type?: "patient" | string;
  fullWidth?: boolean;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: IOption;
  sx?: any;
  handleCallback?: (values: IOption[]) => void;
  errorStyle?: any;
  limitTags?: number;
  maxWidth?: number;
  required?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  handleStartAdornmentClick?: () => void;
  handleEndAdornmentClick?: () => void;
}

function MultiAutocompleteField(props: IProps) {
  const {
    field,
    form,
    label,
    options = [],
    fullWidth,
    sx,
    limitTags = 1,
    placeholder,
    handleCallback,
    disabled = false,
    startIcon,
    endIcon,
    handleStartAdornmentClick,
    handleEndAdornmentClick,
  } = props;

  const { name, value } = field;
  const { errors, touched, setFieldValue } = form;
  const showError = touched[name] && errors[name];
  const { t } = useTranslation();

  // Ensure value is always an array
  const safeValue = useMemo(() => {
    return Array.isArray(value) ? value : [];
  }, [value]);

  // Function to find matching options based on ID, code, or value
  const findSelectedOptions = (selectedValues: any[]): IOption[] => {
    if (
      !selectedValues ||
      !Array.isArray(selectedValues) ||
      selectedValues.length === 0
    ) {
      return [];
    }

    return selectedValues
      .map((selected) => {
        if (!selected) return null;

        // Try to find the option in options by ID, code, or value (most stable identifiers)
        const matchingOption = options.find(
          (option) =>
            (selected.id !== undefined && option.id === selected.id) ||
            (selected.code !== undefined && option.code === selected.code) ||
            (selected.value !== undefined && option.value === selected.value)
        );

        // If we couldn't find by ID/code/value, try by label as last resort
        if (!matchingOption && selected.label) {
          const labelMatch = options.find(
            (option) => option.label === selected.label
          );
          if (labelMatch) return labelMatch;
        }

        // Return the matching option or the selected value as fallback
        return matchingOption || selected;
      })
      .filter(Boolean) as IOption[];
  };

  // Set the current value as a state
  const [currentValue, setCurrentValue] = useState<IOption[]>(
    findSelectedOptions(safeValue)
  );

  const handleChange = (event: SyntheticEvent, newValues: IOption[] | null) => {
    const safeNewValues = newValues || [];

    // Update Formik value
    setFieldValue(name, safeNewValues);

    // Update local state
    setCurrentValue(safeNewValues);

    // Call the callback if provided
    handleCallback?.(safeNewValues);
  };

  useEffect(() => {
    // Update current value when the field value changes or options change
    const selectedOptions = findSelectedOptions(safeValue);
    setCurrentValue(selectedOptions);
  }, [safeValue, options]);

  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      value={currentValue}
      size="small"
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (!option || !value) return false;

        // First check by ID or unique identifiers
        if (option.id !== undefined && value.id !== undefined) {
          return option.id === value.id;
        }

        if (option.code !== undefined && value.code !== undefined) {
          return option.code === value.code;
        }

        if (option.value !== undefined && value.value !== undefined) {
          return option.value === value.value;
        }

        // Only fall back to label if no other identifiers exist
        return option.label === value.label;
      }}
      getOptionLabel={(option: IOption) => {
        // Safe access to option properties
        if (!option) return "";

        // Translate the label
        return t(option.label as string, {
          defaultValue: (option.label as string) || "",
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!showError}
          helperText={showError && errors[name]}
          label={t(label)}
          placeholder={t(placeholder as string, {
            defaultValue: placeholder as string,
          })}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startIcon && (
                  <InputAdornment position="start">
                    <IconButton
                      color={showError ? "error" : "default"}
                      disabled={disabled || !handleStartAdornmentClick}
                      onClick={handleStartAdornmentClick}
                      edge="start"
                      sx={{ width: 30, height: 30, m: 0 }}
                    >
                      {startIcon}
                    </IconButton>
                  </InputAdornment>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {endIcon && (
                  <InputAdornment position="end">
                    <IconButton
                      color={showError ? "error" : "default"}
                      disabled={disabled || !handleEndAdornmentClick}
                      onClick={handleEndAdornmentClick}
                      edge="end"
                      sx={{ width: 30, height: 30, m: 0 }}
                    >
                      {endIcon}
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      )}
      onChange={handleChange}
      fullWidth={fullWidth}
      sx={sx}
      renderOption={(props, option: IOption) => {
        const { key, ...otherProps } = props;
        return (
          <li key={key} {...otherProps}>
            {t(option?.label as string, {
              defaultValue: option?.label as string,
            })}
          </li>
        );
      }}
    />
  );
}

export default MultiAutocompleteField;
