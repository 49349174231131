import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
  AppBar,
  Toolbar,
  Avatar,
  Stack,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ColorModeContext } from "../../theme";
import { SETTINGS } from "../../constants/ConstantCommon";
import { createStorage } from "../../utils/LocalStorage";
import userApi from "../../api/userApi";
import { SIGNIN } from "constants/routes";
import { Images } from "assets/images";
import LanguageSelector from "./LanguageSelector";
import "./styles.scss";
import TopbarMenuItem from "./TopbarMenuItem";
import useMenu from "hooks/useMenu/useMenu";

const TopBar = () => {
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const colorMode: any = useContext(ColorModeContext);

  const menu = useMenu();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e: any) => {
    setAnchorElUser(null);
    if (isLogout(e.target.id)) handleLogout();
  };

  const handleOpenUserProfile = () => {
    setAnchorElUser(null);
    navigate("/user-profile");
  };

  const isLogout = (id: string) => id === "logout";

  const handleLogout = async () => {
    const storage = createStorage("citysports");
    try {
      const { tokens } = await storage.get("account");
      const token = { refresh: tokens.refresh };
      await userApi.logout(token);

      storage.delete();
      navigate(SIGNIN, { replace: true });
    } catch (error) {
      storage.delete();
      navigate(SIGNIN, { replace: true });
    }
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Avatar
          alt="Logo"
          src={Images.Logo}
          sx={{
            width: 50,
            height: 35,
            display: { xs: "none", md: "flex" },
          }}
          variant="square"
        />
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {menu.map((item, index) => (
              <MenuItem key={index} onClick={handleCloseNavMenu}>
                <TopbarMenuItem {...item} />
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* ICONS */}
        <Stack direction="row" spacing={1}>
          <IconButton onClick={colorMode.toggleColorMode} className="icon">
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>

          <IconButton className="icon">
            <NotificationsOutlinedIcon />
          </IconButton>

          <IconButton className="icon">
            <SettingsOutlinedIcon />
          </IconButton>

          <Tooltip title="Hồ sơ của bạn">
            <IconButton onClick={handleOpenUserMenu} className="icon">
              <PersonOutlinedIcon />
            </IconButton>
          </Tooltip>
          <LanguageSelector />

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {Array.from(SETTINGS).map(([key, value]) => (
              <MenuItem
                id={key}
                key={`setting${key}`}
                onClick={
                  key === "profile"
                    ? handleOpenUserProfile
                    : handleCloseUserMenu
                }
              >
                <Typography textAlign="center" id={key}>
                  {value}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
