import { TEACHER, TEACHER_DETAIL } from "constants/routes";
import TeacherList from ".";
import TeacherDetail from "./TeacherDetail/TeacherDetail";

const teacherRoute = {
  path: TEACHER,
  element: TeacherList,
};

const teacherDetailRoute = {
  path: TEACHER_DETAIL,
  element: TeacherDetail,
};

export default [teacherRoute, teacherDetailRoute];
