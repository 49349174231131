import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, JSX } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useModalContext from "hooks/useModalContext/useModalContext";
import { useMutation } from "@tanstack/react-query";
import ResetPasswordContent from "./ResetPasswordContent";
import { POPUP_NO_ACTION, POPUP_NORMAL } from "constants/ConstantCommon";
import { toast } from "react-toastify";

interface ActionCellProps {
  row: {
    id: string;
    fullName?: string;
    name?: string;
    doctorName?: string;
    status?: string;
    active?: boolean;
    isActive?: boolean;
    isPending?: boolean;
    isLocked?: boolean;
    avatar?: any;
  };
  detailUrl?: string;
  disableResetPassword?: boolean;
  disableLock?: boolean;
  disableDelete?: boolean;
  handleDelete?: (id: string) => Promise<void>;
  handleCallback?: () => void;
  handleActivate?: (row: any) => Promise<void>;
  handleDeactivate?: (row: any) => Promise<void>;
}

const ActionCell = ({
  row,
  detailUrl,
  disableResetPassword,
  disableLock,
  disableDelete,
  handleCallback,
  handleDelete = async () => Promise.resolve(),
  handleActivate = async () => Promise.resolve(),
  handleDeactivate = async () => Promise.resolve(),
}: ActionCellProps) => {
  const {
    setOpen: setPopupOpen,
    setTitle: setPopupTitle,
    setContent: setPopupContent,
    setOkButton,
    setType: setPopupType,
  } = useModalContext();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleError = () => console.error("An error occurred");

  const status = getStatus(row);

  const menuItems = getMenuItems(status, {
    detailUrl,
    disableLock,
    disableResetPassword,
    disableDelete,
    handleDelete,
  });

  const deleteItem = useMutation({
    mutationFn: handleDelete,
    onSuccess: () => {
      closePopup();
      handleCallback?.();
      toast.success(
        `${t("Deleted")} ${row?.name ?? row?.fullName} ${t("successfully")}`
      );
    },
    onError: handleError,
  });

  const activateItem = useMutation({
    mutationFn: handleActivate,
    onSuccess: () => {
      closePopup();
      handleCallback?.();
    },
    onError: handleError,
  });

  const deactivateItem = useMutation({
    mutationFn: handleDeactivate,
    onSuccess: () => {
      closePopup();
      handleCallback?.();
    },
    onError: handleError,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const closePopup = () => setPopupOpen(false);

  const handleMenuItemClick = (action: string) => {
    handleClose();

    switch (action) {
      case "View Detail":
        detailUrl && navigate(detailUrl);
        break;
      case "Delete":
        openDeletePopup(row, deleteItem);
        break;
      case "Reset Password":
        openResetPasswordPopup();
        break;
      case "Activate":
        openActivatePopup(row, activateItem);
        break;
      case "Deactivate":
        openDeactivatePopup(row, deactivateItem);
        break;
      case "Complete Service":
        console.log("Completing Service for:", row.id);
        break;
      default:
        break;
    }
  };

  const openDeletePopup = (row: ActionCellProps["row"], deleteItem: any) => {
    setPopupOpen(true);
    setPopupType && setPopupType(POPUP_NORMAL);
    setPopupTitle(t("Warning"));
    setPopupContent(
      `${t("Are you sure to delete")} ${
        row.fullName ?? row.name ?? row?.doctorName ?? t("this item")
      }?`
    );
    setOkButton(
      <Button
        variant="contained"
        size="small"
        sx={{ textTransform: "none" }}
        onClick={() => deleteItem.mutate(row.id)}
      >
        {t("Ok")}
      </Button>
    );
  };

  const openResetPasswordPopup = () => {
    setPopupOpen(true);
    setPopupType && setPopupType(POPUP_NO_ACTION);
    setPopupTitle(t("Reset Password"));
    setPopupContent(<ResetPasswordContent id={row.id} />);
  };

  const openActivatePopup = (
    row: ActionCellProps["row"],
    activateItem: any
  ) => {
    setPopupOpen(true);
    setPopupType && setPopupType(POPUP_NORMAL);
    setPopupTitle(t("Warning"));
    setPopupContent(
      `${t("Are you sure to activate")} ${
        row.fullName ?? row.name ?? t("this item")
      }?`
    );
    setOkButton(
      <Button
        variant="contained"
        size="small"
        sx={{ textTransform: "none" }}
        onClick={() => activateItem.mutate(row)}
      >
        {t("Ok")}
      </Button>
    );
  };

  const openDeactivatePopup = (
    row: ActionCellProps["row"],
    deactivateItem: any
  ) => {
    setPopupOpen(true);
    setPopupType && setPopupType(POPUP_NORMAL);
    setPopupTitle(t("Warning"));
    setPopupContent(
      `${t("Are you sure to deactivate")} ${
        row?.fullName ?? row?.name ?? t("this item")
      }?`
    );
    setOkButton(
      <Button
        variant="contained"
        size="small"
        sx={{ textTransform: "none" }}
        onClick={() => deactivateItem.mutate(row)}
      >
        {t("Ok")}
      </Button>
    );
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.filter(isValidMenuItem).map((item, index) => (
          <MenuItemContent
            key={index}
            item={item}
            handleMenuItemClick={handleMenuItemClick}
          />
        ))}
      </Menu>
    </Box>
  );
};

const getStatus = (row: ActionCellProps["row"]) =>
  row?.status ??
  (row?.active ?? row?.isActive
    ? "Active"
    : row?.isPending
    ? "Pending"
    : row?.isLocked
    ? "Locked"
    : row?.status === "Inactive"
    ? "Inactive"
    : row?.status === "Arrived"
    ? "Arrived"
    : "");

const getMenuItems = (
  status: string,
  options: {
    detailUrl?: string | undefined;
    disableLock?: boolean;
    disableResetPassword?: boolean;
    disableDelete?: boolean;
    handleDelete?: (id: string) => Promise<void>;
  }
) => {
  let items: { text: string; isDividerNeeded: boolean }[] = [];
  switch (status) {
    case "Active":
      items = [
        {
          text: "View Detail",
          isDividerNeeded: Boolean(options?.disableResetPassword),
        },
        { text: "Reset Password", isDividerNeeded: true },
        { text: "Deactivate", isDividerNeeded: false },
        { text: "Delete", isDividerNeeded: false },
      ];
      break;
    case "Pending":
      items = [
        { text: "View Detail", isDividerNeeded: true },
        { text: "Activate", isDividerNeeded: false },
        { text: "Delete", isDividerNeeded: false },
      ];
      break;
    case "Locked":
      items = [
        { text: "View Detail", isDividerNeeded: true },
        { text: "Unlock", isDividerNeeded: false },
        { text: "Deactivate", isDividerNeeded: false },
      ];
      break;
    case "Inactive":
      items = [
        { text: "View Detail", isDividerNeeded: true },
        { text: "Activate", isDividerNeeded: false },
        { text: "Delete", isDividerNeeded: false },
      ];
      break;
    default:
      items = options?.handleDelete
        ? [
            { text: "View Detail", isDividerNeeded: true },
            { text: "Delete", isDividerNeeded: false },
          ]
        : [{ text: "View Detail", isDividerNeeded: false }];
  }

  if (options.disableLock) {
    items = items.filter(
      (item) => item.text !== "Lock" && item.text !== "Unlock"
    );
  }
  if (options.disableResetPassword) {
    items = items.filter((item) => item.text !== "Reset Password");
  }
  if (options.disableDelete) {
    items = items.filter((item) => item.text !== "Delete");
  }

  return items;
};

const isValidMenuItem = (item: {
  text: string;
  isDividerNeeded: boolean;
}): item is { text: string; isDividerNeeded: boolean } & {
  text:
    | "Lock"
    | "Unlock"
    | "Reset Password"
    | "Delete"
    | "View Detail"
    | "Deactivate"
    | "Activate"
    | "Mark as Arrived"
    | "Cancel Booking"
    | "Complete Service"
    | "No-show";
} => {
  return [
    "Lock",
    "Unlock",
    "Reset Password",
    "Delete",
    "View Detail",
    "Deactivate",
    "Activate",
    "Mark as Arrived",
    "Cancel Booking",
    "Complete Service",
    "No-show",
  ].includes(item.text);
};

const MenuItemContent = ({
  item,
  handleMenuItemClick,
}: {
  item: { text: string; isDividerNeeded: boolean };
  handleMenuItemClick: (action: string) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const icons: Record<
    | "Lock"
    | "Unlock"
    | "Delete"
    | "Mark as Arrived"
    | "Cancel Booking"
    | "Complete Service"
    | "No-show",
    JSX.Element
  > = {
    Lock: <LockIcon sx={{ color: theme.palette.warning.light, mr: 1 }} />,
    Unlock: <LockOpenIcon sx={{ color: theme.palette.warning.main, mr: 1 }} />,
    Delete: <DeleteIcon sx={{ color: theme.palette.error.main, mr: 1 }} />,
    "Mark as Arrived": (
      <LockOpenIcon sx={{ color: theme.palette.success.main, mr: 1 }} />
    ),
    "Cancel Booking": (
      <DeleteIcon sx={{ color: theme.palette.error.main, mr: 1 }} />
    ),
    "Complete Service": (
      <LockIcon sx={{ color: theme.palette.info.main, mr: 1 }} />
    ),
    "No-show": <DeleteIcon sx={{ color: theme.palette.error.dark, mr: 1 }} />,
  };

  let textColor = "";

  switch (item.text) {
    case "Activate":
    case "Mark as Arrived":
      textColor = "success";
      break;
    case "Deactivate":
    case "Delete":
    case "Cancel Booking":
    case "No-show":
      textColor = "error";
      break;
    case "Lock":
      textColor = "warning";
      break;
    default:
      textColor = "";
      break;
  }

  return (
    <Box px={1}>
      <MenuItem
        onClick={() => handleMenuItemClick(item.text)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: icons[item.text as keyof typeof icons]
            ? "space-between"
            : "flex-end",
        }}
      >
        {icons[item.text as keyof typeof icons] &&
          icons[item.text as keyof typeof icons]}
        <Typography color={textColor}>
          {t(item.text, { defaultValue: item.text })}
        </Typography>
      </MenuItem>
      {item.isDividerNeeded && <Divider />}
    </Box>
  );
};

export default ActionCell;
