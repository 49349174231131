import React from "react";
import FacebookLogin, {
  ReactFacebookLoginProps,
  ReactFacebookFailureResponse,
} from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "./styles.scss";
import { createStorage } from "utils/LocalStorage";
import { userApi } from "api/tokenApi";

interface FacebookResponse extends ReactFacebookLoginProps {
  accessToken?: string;
  status?: string;
}

const FacebookSignin: React.FC = () => {
  const appId = String(import.meta.env.VITE_FACEBOOK_APP_ID);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const login = useMutation({
    mutationFn: (auth_token: { auth_token: string }) =>
      userApi.loginFacebook(auth_token),
    onSuccess: (response) => {
      const { data } = response;
      const storage = createStorage("citysports");
      storage.set("account", data);
      queryClient.clear(); // Optional: clear any existing queries
      navigate("/portal", { replace: true });
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || "Login failed";
      alert(errorMessage);
    },
  });

  const responseFacebook = async (
    response: FacebookResponse | ReactFacebookFailureResponse
  ) => {
    const accessToken =
      "accessToken" in response ? response.accessToken : undefined;

    if (accessToken) {
      const auth_token = { auth_token: accessToken };
      login.mutate(auth_token);
    }
  };

  const handleClick = (event?: React.MouseEvent<HTMLDivElement>) => {
    console.log("handleClick", event);
  };

  // Use type assertion to resolve the JSX element type issue
  const FacebookLoginButton =
    FacebookLogin as unknown as React.ComponentType<ReactFacebookLoginProps>;

  return (
    <FacebookLoginButton
      appId={appId}
      autoLoad={false}
      fields="name,email,picture"
      onClick={handleClick}
      callback={responseFacebook}
      cssClass="btnFacebook"
      icon="fa-facebook"
      textButton="&nbsp;&nbsp;Đăng nhập bằng Facebook"
    />
  );
};

export default FacebookSignin;
