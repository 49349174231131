import React, { ReactNode } from "react";
import { Persistor } from "redux-persist";

interface CustomPersistGateProps {
  persistor: Persistor;
  loading: React.ReactNode;
  children: ReactNode;
}

export const CustomPersistGate: React.FC<CustomPersistGateProps> = ({
  children,
  loading,
  persistor,
}) => {
  const [bootstrapped, setBootstrapped] = React.useState(false);

  React.useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const onPersistorState = () => {
      const { bootstrapped: persistorBootstrapped } = persistor.getState();
      if (persistorBootstrapped) {
        setBootstrapped(true);
        if (unsubscribe) unsubscribe();
      }
    };

    onPersistorState();
    unsubscribe = persistor.subscribe(onPersistorState);

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [persistor]);

  if (!bootstrapped) {
    return loading === null ? null : <>{loading}</>;
  }

  return <>{children}</>;
};
