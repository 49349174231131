import { STUDENT, STUDENT_DETAIL } from "constants/routes";
import StudentList from ".";
import StudentDetail from "./StudentDetail/StudentDetail";

const studentRoute = {
  path: STUDENT,
  element: StudentList,
};

const studentDetailRoute = {
  path: STUDENT_DETAIL,
  element: StudentDetail,
};

export default [studentRoute, studentDetailRoute];
