import { Typography } from "@mui/material";

export const TEACHER_TABLE = [
  { name: "name", label: "Họ tên", fieldType: "InputField" },
  {
    name: "locations",
    label: "Cơ sở",
    options: "locationList",
    fieldType: "MultiAutocompleteField",
  },
  { name: "phone", label: "Số điện thoại", fieldType: "InputField" },
  { name: "role", label: "chức vụ", fieldType: "InputField" },
  { name: "start_date", label: "Ngày nhận việc", fieldType: "DatePickerField" },
  {
    name: "salary",
    label: "Mức lương",
    fieldType: "InputField",
    inputProps: { min: 0, step: 10000 },
    currencyType: "VND",
    isCurrency: true,
    endIcon: <Typography variant="h5">đ</Typography>,
  },
  {
    name: "allowance",
    label: "Lương khác",
    inputProps: { min: 0, step: 10000 },
    currencyType: "VND",
    fieldType: "InputField",
    isCurrency: true,
    endIcon: <Typography variant="h5">đ</Typography>,
  },
  { name: "seniority", label: "Thâm niên", fieldType: "InputField" },
];
