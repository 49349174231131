import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { IMenuItem } from "hooks/useMenu/useMenu";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { tokens } from "theme";

const TopbarMenuItem = (props: IMenuItem) => {
  const { title, path = "../", icon: Icon, submenu } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (submenu) {
    return submenu.map((subItem: any, index: number) => {
      const SubIcon = subItem.icon;
      return (
        <NavLink
          key={index}
          style={{ textDecoration: "none" }}
          to={subItem.path}
        >
          {({ isActive }) => (
            <MenuItem
              component={"span"}
              // active={isActive}
              sx={{
                color: isActive
                  ? theme.palette.primary.light
                  : theme.palette.text.primary,
                backgroundColor: isActive
                  ? colors.grey[900]
                  : theme.palette.background.paper,
                position: "relative",
              }}
              // icon={<SubIcon />}
              className="menu-item-hover"
            >
              <Typography>{t(subItem.title)}</Typography>
            </MenuItem>
          )}
        </NavLink>
      );
    });
  } else {
    return (
      <NavLink style={{ textDecoration: "none" }} to={path}>
        {({ isActive }) => (
          <Box
            display="flex"
            m={0.5}
            sx={{
              color: isActive
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{t(title, { defaultValue: title })}</ListItemText>
          </Box>
        )}
      </NavLink>
    );
  }
};

export default TopbarMenuItem;
